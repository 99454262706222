import { useState } from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CircleIcon from '@mui/icons-material/Circle';
import noPhoto from '../../../assets/images/no-photo.png';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import SaveSlideBar from '../../SaveSlideBar';
import { editSettings } from '../../../Api/Admin/settings';
import { useLayoutEffect } from 'react';

const ProfileSettings = ({ fetchCurrentSettings, currentSettings }) => {
  const [isSaveBar, setIsSaveBar] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  });

  const handleFormData = (key, selectedValue) => {
    setFormData({ ...formData, [key]: selectedValue });
    setIsSaveBar(true);
  };

  // API

  // populating the data
  useLayoutEffect(() => {
    setFormData(currentSettings);
  }, []);

  // handlers
  const handleSave = () => {
    let dataObj = {};

    for (const key in formData) {
      if (formData[key].length !== 0) {
        dataObj = { ...dataObj, [key]: formData[key] };
      }
    }

    editSettings(dataObj).then((res) => {
      fetchCurrentSettings();
      if (res.status === 200) {
        setIsSaveBar(false);
      }
    });
  };

  const handleCancel = () => {
    setFormData(currentSettings);
    setIsSaveBar(false);
  };

  return (
    <>
      <Grid
        xs={12}
        sx={{
          marginTop: { xs: 9, sm: 14 },
          backgroundColor: 'white',
        }}
      >
        <Grid
          sx={{
            padding: { md: '15px 25px', xs: '15px 15px' },
            borderBottom: '1px solid #e3eaf3',
          }}
        >
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: 500,
              color: '#3A3A50',
            }}
          >
            Account Settings{' '}
            <span style={{ color: '#c1c1c1' }}>
              <CircleIcon sx={{ fontSize: '5px', marginX: '3px' }} /> Profile
            </span>
          </Typography>
        </Grid>
        <Grid container sx={{ padding: { md: '15px 25px', xs: '15px 15px' } }}>
          <Grid xs={12} sx={{ marginY: '10px' }}>
            <img style={{ borderRadius: '50%' }} src={noPhoto} alt="" />
          </Grid>

          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography
                variant="subtitle2"
                sx={{
                  color: '#3A3A50',
                }}
              >
                Name:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={formData?.name}
                onChange={(e) => handleFormData('name', e.target.value)}
                id="outlined-basic"
                size="small"
                required
                variant="outlined"
                sx={{ marginBottom: '2rem', width: '100%', fontSize: '14px' }}
              />
            </Grid>
          </Grid>

          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography
                variant="subtitle2"
                sx={{
                  color: '#3A3A50',
                }}
              >
                Last Name:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={formData?.lastName}
                onChange={(e) => handleFormData('lastName', e.target.value)}
                id="outlined-basic"
                size="small"
                required
                variant="outlined"
                sx={{ marginBottom: '2rem', width: '100%', fontSize: '14px' }}
              />
            </Grid>
          </Grid>

          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography
                variant="subtitle2"
                sx={{
                  color: '#3A3A50',
                }}
              >
                Email:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={formData?.email}
                onChange={(e) => handleFormData('email', e.target.value)}
                id="outlined-basic"
                size="small"
                required
                variant="outlined"
                sx={{ marginBottom: '2rem', width: '100%', fontSize: '14px' }}
              />
            </Grid>
          </Grid>

          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography
                variant="subtitle2"
                sx={{
                  color: '#3A3A50',
                }}
              >
                Phone Number:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <PhoneInput
                placeholder="Enter phone number"
                value={formData?.phoneNumber}
                onChange={(value) => handleFormData('phoneNumber', value)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SaveSlideBar
        handleCancel={handleCancel}
        handleSave={handleSave}
        isSaveBar={isSaveBar}
        setIsSaveBar={setIsSaveBar}
      />
    </>
  );
};

export default ProfileSettings;
