import { Grid, Typography, useMediaQuery } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { useState } from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LanguageIcon from '@mui/icons-material/Language';
import classes from './settingsSidebar.module.css';
import TabSettings from './TabSettings';

const SettingsSidebar = ({
  activeTab,
  handleActiveTab,
  openMenu,
  handleOpenMenu,
  mediaQuery1250,
}) => {
  const mediaQuery1020 = useMediaQuery('(min-width:1020px)');

  return (
    <>
      {!openMenu && (
        <Grid
          onClick={handleOpenMenu}
          sx={{
            position: 'fixed',
            top: '105px',
            display: { xs: 'flex', md: 'none' },
            height: '46px',
            width: '46px',
            backgroundColor: '#6C70DC',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '0px 2px 2px 0px',
            zIndex: 1,
          }}
        >
          <KeyboardArrowRightIcon
            style={{ fontSize: '26px', color: 'white' }}
          />
        </Grid>
      )}
      {openMenu && (
        <Grid
          onClick={handleOpenMenu}
          sx={{
            position: 'fixed',
            top: '105px',
            right: '0px',
            display: { xs: 'flex', md: 'none' },
            height: '46px',
            width: '46px',
            backgroundColor: '#6C70DC',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '0px 2px 2px 0px',
          }}
        >
          <KeyboardArrowLeftIcon style={{ fontSize: '26px', color: 'white' }} />
        </Grid>
      )}

      <Grid
        sm={12}
        md={mediaQuery1250 ? 3 : 4}
        sx={{
          display: openMenu ? 'block' : { xs: 'none', md: 'block' },
          boxShadow: '0 0 30px 0 rgb(0 0 0 / 5%)',
          backgroundColor: 'white',
          height: '90vh',
          padding: mediaQuery1020 ? '40px' : '20px',
          overflow: 'auto',
        }}
      >
        <Typography
          sx={{
            fontSize: '28px',
            display: { xs: 'none', sm: 'block' },
            color: '#3A3A50',
          }}
        >
          Settings
        </Typography>
        <Grid
          className={classes.settingsSidebarContainer}
          sm={12}
          sx={{ marginTop: '20px' }}
        >
          <TabSettings
            tabLabel="General settings"
            tabName="general-settings"
            icon={<SettingsIcon sx={{ color: 'white' }} />}
            iconColor="#53d56c"
            description="You can customize general settings about booking from here"
            handleActiveTab={handleActiveTab}
            activeTab={activeTab}
          />

          <TabSettings
            tabLabel="Account settings"
            tabName="account-settings"
            icon={<ManageAccountsIcon sx={{ color: 'white' }} />}
            iconColor="#53d56c"
            description="You can customize Account settings about booking from here"
            handleActiveTab={handleActiveTab}
            activeTab={activeTab}
            subTabs={[
              {
                subTabLabel: 'Profile Settings',
                subTabName: 'profile-settings',
              },
              {
                subTabLabel: 'Change Password',
                subTabName: 'change-password',
              },
            ]}
          />

          <TabSettings
            tabLabel="Payment settings"
            tabName="payment-settings"
            icon={<AccountBalanceWalletIcon sx={{ color: 'white' }} />}
            iconColor="#26c0d6"
            description="Currency, price format , general settings about payment , paypal, stripe and so on"
            handleActiveTab={handleActiveTab}
            activeTab={activeTab}
            subTabs={[
              {
                subTabLabel: 'General',
                subTabName: 'general',
              },
              {
                subTabLabel: 'Payment Methods',
                subTabName: 'payment-methods',
              },
            ]}
          />

          <TabSettings
            tabLabel="Language"
            tabName="language-settings"
            icon={<LanguageIcon sx={{ color: 'white' }} />}
            iconColor="#ba67a7"
            description="Edit Language Settings from this page"
            handleActiveTab={handleActiveTab}
            activeTab={activeTab}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SettingsSidebar;
