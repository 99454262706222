import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';

import LanguageIcon from '@mui/icons-material/Language';
import { useState } from 'react';
import CustomTableFooter from '../../CustomTableFooter';
import StatusChip from '../../StatusChip';
import ShareModal from '../../Modal';

const demoRows = [
  {
    id: 1,
    name: 'English',
    shortForm: 'en',
    direction: 'LTR',
    status: 'Active',
  },
  {
    id: 2,
    name: 'Arabic',
    shortForm: 'ar',
    direction: 'RTL',
    status: 'Inactive',
  },
  {
    id: 4,
    name: 'Swedish',
    shortForm: 'sv',
    direction: 'LTR',
    status: 'Inactive',
  },
];

const Language = ({ handleTranslateLanguage }) => {
  const [modalType, setModalType] = useState('');
  const [modalData, setModalData] = useState({});
  const [openModal, setOpenModal] = useState({ open: false, type: null });
  const [selectionModel, setSelectionModel] = useState([]);

  const handleOpenModal = () => {
    setOpenModal({ open: false, type: null });
  };

  const handleDeleteOpenModal = () => {
    setOpenModal({ open: true, type: 'delete-modal' });
  };

  const columns = [
    {
      field: 'name',
      headerName: 'NAME',
      editable: false,
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'shortForm',
      headerName: 'SHORT FORM',
      editable: false,
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'direction',
      headerName: 'DIRECTION',

      editable: false,
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'STATUS',
      type: 'singleSelect',
      valueOptions: ['Active', 'Inactive'],
      editable: false,
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <>
          {params.value === 'Active' ? (
            <StatusChip
              text={params.value}
              color={'#EA9B7F'}
              bgColor={'#FBEEE9'}
              rounded={true}
            />
          ) : (
            <StatusChip
              text={params.value}
              color={'#DF3E70'}
              bgColor={'#F9E0E7'}
              rounded={true}
            />
          )}
        </>
      ),
    },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      type: 'actions',
      align: 'right',
      minWidth: 100,
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          label="Translate language"
          onClick={() => handleTranslateLanguage(params.row.name)}
          showInMenu
        />,
        <GridActionsCellItem
          label="Edit"
          onClick={() => {
            setModalType('edit');
            setOpenModal({ open: true, type: 'edit-language' });
            setModalData({
              id: params.row.id,
              fullName: params.row.full_name,
              email: params.row.email,
              plan: params.row.plan,
              expiry: params.row.expiry,
            });
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label={params.row.status === 'Active' ? 'Deactivate' : 'Active'}
          onClick={() => {
            setModalType('edit');
            setModalData({
              id: params.row.id,
              fullName: params.row.full_name,
              email: params.row.email,
              plan: params.row.plan,
              expiry: params.row.expiry,
            });
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          onClick={() => {
            setOpenModal({ open: true, type: 'delete-modal' });
          }}
          showInMenu
        />,
      ],
    },
  ];

  return (
    <>
      <Modal open={openModal.open}>
        <>
          {openModal.type === 'add-language' && (
            <ShareModal
              handleOpenModal={handleOpenModal}
              type={'ADD_LANGUAGE'}
              open={openModal.open}
            />
          )}
          {openModal.type === 'edit-language' && (
            <ShareModal
              handleOpenModal={handleOpenModal}
              type={'EDIT_LANGUAGE'}
              open={openModal.open}
            />
          )}
          {openModal.type === 'delete-modal' && (
            <ShareModal
              handleOpenModal={handleOpenModal}
              type={'DELETE'}
              open={openModal.open}
            />
          )}
        </>
      </Modal>

      <Grid container xs={12} style={{ justifyContent: 'flex-end' }}>
        <Button
          sx={{
            width: { xs: '100%', sm: 'auto' },
            marginTop: { xs: 9, sm: 5 },
            marginBottom: 1,
            marginLeft: { xs: 0, sm: 1 },
            backgroundColor: '#9387ed',
            height: '45px',
            borderRadius: '2px',
            fontSize: '12px',
            fontWeight: 600,
          }}
          variant="contained"
          onClick={() => setOpenModal({ open: true, type: 'add-language' })}
        >
          <LanguageIcon sx={{ marginRight: 1, fontSize: '20px' }} />
          Add Language
        </Button>
      </Grid>

      <Grid xs={12} style={{ backgroundColor: 'white', marginTop: '20px' }}>
        <Grid
          sx={{
            padding: { md: '15px 25px', xs: '15px 15px' },
            borderBottom: '1px solid #e3eaf3',
          }}
        >
          <Typography
            sx={{ fontSize: '18px', fontWeight: 500, color: '#3A3A50' }}
          >
            Language
          </Typography>
        </Grid>
        <Grid container style={{ padding: '15px 0' }}>
          <Grid container item justifyContent="space-between">
            <div style={{ height: 400, width: '100%' }}>
              <DataGrid
                sx={{
                  border: 0,
                  '& .MuiDataGrid-columnHeaderTitle': {
                    fontSize: '12px',
                    fontWeight: '900 !important',
                    color: '#3A3A50',
                  },
                  '& .MuiDataGrid-main, .MuiDataGrid-footerContainer': {
                    backgroundColor: 'white',
                    border: 'none',
                  },
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-cell': {
                    color: '#3A3A50',
                  },
                  minHeight: '60vh',
                }}
                rows={demoRows}
                columns={columns}
                checkboxSelection
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
                components={{
                  Footer: () => (
                    <CustomTableFooter
                      selectionModel={selectionModel}
                      handleDeleteOpenModal={handleDeleteOpenModal}
                    />
                  ),
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Language;
