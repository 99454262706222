import React, { useLayoutEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Drawer from '../../components/Drawer';
import Details from './Details';
import Permissions from './Permissions';
import Limits from './Limits';
import { useMediaQuery, useTheme } from '@mui/material';
import DrawerFooter from '../../components/DrawerFooter';
import DrawerHeader from '../../components/DrawerHeader';
import { addPlan, editPlanById } from '../../Api/Admin/plan';
import { useRef } from 'react';
import CustomButtonPages from '../../components/CustomButtonPages';

const Add = ({ toggleAddPlansDrawer, drawerType, drawerData, fetchPlans }) => {
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));

  const buttonProps = {
    variant: 'contained',
    size: 'large',
  };

  const [quillHtml, setQuillHtml] = useState('');
  const [name, setName] = useState('');
  const [bookmark, setBookmark] = useState('');
  const [color, setColor] = useState('');
  const [orderNumber, setOrderNumber] = useState('');
  const [monthlyPrice, setMonthlyPrice] = useState('');
  const [discountFirstMonth, setDiscountFirstMonth] = useState('');
  const [annualPrice, setAnnualPrice] = useState('');
  const [discountFirstYear, setDiscountFirstYear] = useState('');
  const [hiddenPlan, setHiddenPlan] = useState(true);
  const [activeTab, setActiveTab] = useState('details');
  const [permissions, setPermissions] = useState([]);
  const [limits, setLimits] = useState({
    LOC: 0,
    ST: 0,
    SER: 0,
    SMS: 0,
    EML: 0,
    WAPP: 0,
  });
  const quillRef = useRef();

  const [errors, setErrors] = useState({});
  const requiredFields = [
    'name',
    'bookmark',
    'color',
    'orderNumber',
    'monthlyPrice',
    'discountFirstMonth',
    'annualPrice',
    'discountFirstYear',
    'hiddenPlan',
    'quillHtml',
  ];

  // API

  // handlers
  const handleAddPlan = async () => {
    let data = {
      name,
      bookmark,
      color,
      orderNumber,
      monthlyPrice,
      discountFirstMonth,
      annualPrice,
      discountFirstYear,
      hiddenPlan,
      quillHtml,
    };
    let err = {};
    for (const key in data) {
      if (!data[key] && requiredFields.includes(key)) {
        err = { ...err, [key]: true };
        console.log('s', data[key], data);
      }
      if (
        Array.isArray(data[key]) &&
        data[key].length === 0 &&
        requiredFields.includes(key)
      ) {
        console.log(data[key]);
        err = { ...err, [key]: true };
      }
    }
    setErrors({ ...err });
    if (Object.keys(err).length === 0) {
      addPlan({
        name,
        bookmark,
        color,
        orderNumber,
        mPrice: monthlyPrice,
        discount1M: discountFirstMonth,
        yPrice: annualPrice,
        discount1Y: discountFirstYear,
        hidden: hiddenPlan,
        description: quillHtml,
        permissions: permissions?.join('-'),
        limits: `LOC.${limits['LOC']}-ST.${limits['ST']}-SER.${limits['SER']}-SMS.${limits['SMS']}-EML.${limits['EML']}-WAPP.${limits['WAPP']}`,
      }).then((res) => {
        toggleAddPlansDrawer();
        fetchPlans();
      });
    }
  };

  const handleEditPlan = async () => {
    let data = {
      name,
      bookmark,
      color,
      orderNumber,
      monthlyPrice,
      discountFirstMonth,
      annualPrice,
      discountFirstYear,
      hiddenPlan,
      quillHtml,
    };
    let err = {};
    for (const key in data) {
      if (!data[key] && requiredFields.includes(key)) {
        err = { ...err, [key]: true };
        console.log('s', data[key], data);
      }
      if (
        Array.isArray(data[key]) &&
        data[key].length === 0 &&
        requiredFields.includes(key)
      ) {
        console.log(data[key]);
        err = { ...err, [key]: true };
      }
    }
    setErrors({ ...err });
    if (Object.keys(err).length === 0) {
      editPlanById(
        {
          name,
          bookmark,
          color,
          orderNumber,
          mPrice: monthlyPrice,
          discount1M: discountFirstMonth,
          yPrice: annualPrice,
          discount1Y: discountFirstYear,
          hidden: hiddenPlan,
          description: quillHtml,
          permissions: permissions?.join('-'),
          limits: `LOC.${limits['LOC']}-ST.${limits['ST']}-SER.${limits['SER']}-SMS.${limits['SMS']}-EML.${limits['EML']}-WAPP.${limits['WAPP']}`,
        },
        drawerData.id
      ).then((res) => {
        toggleAddPlansDrawer();
        fetchPlans();
      });
    }
  };

  useLayoutEffect(() => {
    if (drawerType !== 'add') {
      const fetchedLimits = {};

      setName(drawerData.name);
      setBookmark(drawerData.bookmark);
      setColor(drawerData.color);
      setOrderNumber(drawerData.orderNumber);
      setMonthlyPrice(drawerData.mPrice);
      setDiscountFirstMonth(drawerData.discount1M);
      setAnnualPrice(drawerData.yPrice);
      setDiscountFirstYear(drawerData.discount1Y);
      setHiddenPlan(drawerData.is_hidden);
      setPermissions(drawerData.permissions?.split('-'));
      drawerData.limits?.split('-').map((e) => {
        fetchedLimits[e.split('.')[0]] = e.split('.')[1];
      });
      setLimits(fetchedLimits);
      setQuillHtml(drawerData.description);
    }
  }, []);

  // handles the active tab to be shown
  const handleActiveTab = (e) => {
    const elName = e.currentTarget.getAttribute('name');
    setActiveTab(elName);
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={2}>
        <Drawer
          title={drawerType === 'add' ? 'Add Plan' : 'Edit Plan'}
          type={drawerType === 'add' ? 'ADD' : 'EDIT'}
          toggleDrawer={toggleAddPlansDrawer}
        >
          {/* -----------FORM----- */}
          <Grid
            item
            container
            sx={{
              paddingX: 3,
              paddingTop: 3,
              height: '100%',
              overflowY: 'auto',
            }}
          >
            <Grid
              container
              xs={12}
              style={{
                borderBottom: '1px solid #dee2e6',
                marginBottom: '25px',
                height: '45px',
              }}
            >
              <Grid
                name="details"
                item
                style={
                  activeTab === 'details'
                    ? {
                        padding: '7px 15px',
                        cursor: 'pointer',
                        borderBottom: '3px solid #6C70DC',
                      }
                    : { padding: '7px 15px', cursor: 'pointer' }
                }
                onClick={handleActiveTab}
              >
                <Typography
                  variant="subtitle2"
                  style={
                    activeTab === 'details'
                      ? { fontWeight: 600, color: '#292D32' }
                      : { fontWeight: 600, color: '#828F9A' }
                  }
                >
                  Details
                </Typography>
              </Grid>
              <Grid
                name="permissions"
                item
                style={
                  activeTab === 'permissions'
                    ? {
                        padding: '7px 15px',
                        cursor: 'pointer',
                        borderBottom: '3px solid #6C70DC',
                      }
                    : { padding: '7px 15px', cursor: 'pointer' }
                }
                onClick={handleActiveTab}
              >
                <Typography
                  variant="subtitle2"
                  style={
                    activeTab === 'permissions'
                      ? { fontWeight: 600, color: '#292D32' }
                      : { fontWeight: 600, color: '#828F9A' }
                  }
                >
                  Permissions
                </Typography>
              </Grid>
              <Grid
                name="limits"
                item
                style={
                  activeTab === 'limits'
                    ? {
                        padding: '7px 15px',
                        cursor: 'pointer',
                        borderBottom: '3px solid #6C70DC',
                      }
                    : { padding: '7px 15px', cursor: 'pointer' }
                }
                onClick={handleActiveTab}
              >
                <Typography
                  variant="subtitle2"
                  style={
                    activeTab === 'limits'
                      ? { fontWeight: 600, color: '#292D32' }
                      : { fontWeight: 600, color: '#828F9A' }
                  }
                >
                  Limits
                </Typography>
              </Grid>
            </Grid>

            {/* Tabs */}
            {activeTab === 'details' && (
              <Details
                name={name}
                setName={setName}
                bookmark={bookmark}
                setBookmark={setBookmark}
                color={color}
                setColor={setColor}
                orderNumber={orderNumber}
                setOrderNumber={setOrderNumber}
                monthlyPrice={monthlyPrice}
                setMonthlyPrice={setMonthlyPrice}
                discountFirstMonth={discountFirstMonth}
                setDiscountFirstMonth={setDiscountFirstMonth}
                annualPrice={annualPrice}
                setAnnualPrice={setAnnualPrice}
                discountFirstYear={discountFirstYear}
                setDiscountFirstYear={setDiscountFirstYear}
                hiddenPlan={hiddenPlan}
                setHiddenPlan={setHiddenPlan}
                quillRef={quillRef}
                setQuillHtml={setQuillHtml}
                quillHtml={quillHtml}
                errors={errors}
                setErrors={setErrors}
              />
            )}

            {activeTab === 'permissions' && (
              <Permissions
                permissions={permissions}
                setPermissions={setPermissions}
              />
            )}

            {activeTab === 'limits' && (
              <Limits limits={limits} setLimits={setLimits} />
            )}
          </Grid>

          {/* -----Button */}
          <DrawerFooter>
            {drawerType === 'add' && (
              <>
                <div
                  style={{
                    display: 'flex',
                    gap: '15px',
                    justifyContent: isSmallScreen
                      ? 'space-between'
                      : 'flex-end',
                    width: '100%',
                  }}
                >
                  <CustomButtonPages
                    title="Cancel"
                    isIcon={false}
                    onClick={toggleAddPlansDrawer}
                    sx={{
                      width: isSmallScreen ? '48%' : '7rem',
                      marginLeft: { xs: 1, sm: 2 },
                    }}
                  />
                  <CustomButtonPages
                    title="ADD"
                    isIcon={false}
                    blue={true}
                    onClick={handleAddPlan}
                    sx={{
                      width: isSmallScreen ? '48%' : '7rem',
                      marginLeft: { xs: 1, sm: 2 },
                    }}
                  />
                </div>
              </>
            )}
            {drawerType === 'edit' && (
              <>
                <div
                  style={{
                    display: 'flex',
                    gap: '15px',
                    justifyContent: isSmallScreen
                      ? 'space-between'
                      : 'flex-end',
                    width: '100%',
                  }}
                >
                  <CustomButtonPages
                    title="Cancel"
                    isIcon={false}
                    onClick={toggleAddPlansDrawer}
                    sx={{
                      width: isSmallScreen ? '48%' : '7rem',
                      marginLeft: { xs: 1, sm: 2 },
                    }}
                  />
                  <CustomButtonPages
                    title="ADD"
                    isIcon={false}
                    blue={true}
                    onClick={drawerType === 'edit' && handleEditPlan}
                    sx={{
                      width: isSmallScreen ? '48%' : '7rem',
                      marginLeft: { xs: 1, sm: 2 },
                    }}
                  />
                </div>
              </>
            )}
          </DrawerFooter>
        </Drawer>
      </Grid>
    </Grid>
  );
};

export default Add;
