import { Button } from '@mui/material';
import React from 'react';
class CustomButton extends React.Component {
  render() {
    const height = 44;
    return (
      <Button
        onClick={this.props.onClick}
        variant="contained"
        style={{
          width: '100%',
          height,
          backgroundColor: '#4574cd',
          color: '#ffffff',
          marginTop: '2%',
        }}
      >
        {this.props.label}
      </Button>
    );
  }
}

export default CustomButton;
