import { Grid, Typography } from "@mui/material"
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const TabSettings = ({tabLabel, icon, iconColor, description, handleActiveTab, activeTab, tabName, subTabs}) => {

    const handleSubActiveTab = (e, tabName, subTabName) => {
        e.stopPropagation();
        handleActiveTab(tabName, subTabName);
    }

    return(
        <Grid
        name="settings-sidebar-item"
        onClick={() => handleActiveTab(tabName, subTabs ? subTabs[0].subTabName : null)}
        container
        style={activeTab.main === tabName ? {
            background: "linear-gradient(to right, #c0d6df 50%, transparent 0%), linear-gradient(#c0d6df 50%, transparent 0%), linear-gradient(to right, #c0d6df 50%, transparent 0%), linear-gradient(#c0d6df 50%, transparent 0%)",
            backgroundPosition: "top, right, bottom, left",
            backgroundRepeat: "repeat-x, repeat-y",
            backgroundSize: "8px 1px, 1px 8px",
            border: "1px solid #ecf2f5",
            padding: "25px 18px",
            paddingBottom: subTabs ? "10px" : "0px",
            justifyContent: "center",
            boxShadow: "0 0 30px 0 rgb(0 0 0 / 5%)",
        } : {
          border: "1px solid #ecf2f5",
          padding: "25px 18px",
          paddingBottom: subTabs ? "10px" : "0px",
          justifyContent: "center",
          boxShadow: "0 0 30px 0 rgb(0 0 0 / 5%)",
        }}
      >
        <Grid item xs={3}>
          <Grid
            container
            sx={{
              height: "40px",
              width: "40px",
              backgroundColor: `${iconColor}`,
              borderRadius: "14px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {icon}
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={8}
          sm={8}
          sx={{ paddingLeft: {xs: "0", sm: "13px"}, paddingRight: {xs: "0", sm: "13px"}, color: activeTab.main === tabName ? "#303439" : "#828F9A" }}
        >
          <Grid xs={12}>
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: 600, lineHeight: "1.5" }}
          >
            {tabLabel}
          </Typography>
          </Grid>

          <Typography
            variant="caption"
            sx={{ paddingTop: "3px" }}
          >
            {description}
          </Typography>
        </Grid>
        <Grid xs={12} style={{marginTop: "20px"}}>
            {
                subTabs &&
                subTabs.map((subTab, index) => (
                    <Grid onClick={(e) => handleSubActiveTab(e, tabName, subTab.subTabName)} key={`sub-tab-${index}`} container xs={12} style={{padding: "15px 0", justifyContent: "space-between", borderTop: "1px solid #E4EBF4"}}>
                    <Typography variant="subtitle2" sx={{color:  activeTab.sub === subTab.subTabName ? "#6C70DD": "#828F9A", fontWeight: "600"}}>{subTab.subTabLabel}</Typography> <ArrowRightIcon sx={{color: activeTab.sub === subTab.subTabName ? "#6C70DD": "#828F9A"}} />
                </Grid>
                ))
            }

        </Grid>

      </Grid>
    )
}

export default TabSettings