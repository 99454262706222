import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  Grid,
  Icon,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useRef, useState } from 'react';
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded'; // CSV
import MailOutlinedIcon from '@mui/icons-material/MailOutlined'; // send test email
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'; // remove
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation'; // invite provider
import LanguageIcon from '@mui/icons-material/Language'; // Language
import EditRoundedIcon from '@mui/icons-material/EditRounded'; // Edit
import PersonIcon from '@mui/icons-material/Person'; // send welcome email
import AssessmentIcon from '@mui/icons-material/Assessment'; // download full report
import { Modal as MUIModal } from '@mui/material';
import { sendWelcomeEmail } from '../../Api/Admin/tenant';
import { Calendar } from 'react-multi-date-picker';
import { CSVLink } from 'react-csv';
import classes from './Modal.module.css';
import useUploadFile from '../../hooks/useUploadFile';
import { useDispatch } from 'react-redux';
import { callSnackbar } from '../../redux/slices/snackbar.slice';
import CustomButtonPages from '../CustomButtonPages';

const directions = [
  'All users in app',
  'Tentants',
  'Staff',
  'Customers',
  'Company subscriber',
  'User subscriber',
];
const countries = ['All Countries', 'Egypt', 'Sweden'];
const cities = ['All Cities', 'Cairo', 'Alexandria', 'Stockholm'];
const csvHeader = [
  { label: 'Company Name', key: 'companyName' },
  { label: 'Location', key: 'location' },
  { label: 'Industry', key: 'industry' },
  { label: 'Appointments Number', key: 'appointmentNumber' },
  { label: 'Registered Number', key: 'registeredNumber' },
  { label: 'Completed Number', key: 'completedNumber' },
  { label: 'Canceled Number', key: 'canceledNumber' },
  { label: 'Staff Number', key: 'staffNumber' },
  { label: 'Customer', key: 'customer' },
];

const Modal = ({
  handleOpenModal,
  type,
  open,
  handleDelete,
  id,
  email,
  templateCSV,
  uploadLink,
  fetchData,
}) => {
  const dispatch = useDispatch();
  const {
    uploadedFile,
    handleUploadFileToAPI,
    handleDragEnter,
    handleDragLeave,
    handleDragOver,
    handleDrop,
    handleOnChange,
    handleUpload,
    downloadCSVExample,
  } = useUploadFile();
  const [disabled, setDisabled] = useState(false);
  const [bookMarkData, setBookMarkData] = useState({
    service: 'select',
    location: 'select',
    staff: 'select',
  });
  const handelChange = (e) => {
    const { name, value } = e.target;
    setBookMarkData({ ...bookMarkData, [name]: value });
  };

  const [formData, setFormData] = useState({
    languageName: '',
    shortForm: '',
    direction: 'LTR',
  });

  const handleFormData = (fieldName, value) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const csvLinkRef = useRef(null);
  const [lanOpen, setLanOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [calendarState, setCalendarState] = useState([new Date()]);
  const [tenantReportData, setTenantReportData] = useState([]);

  // handle the download of tenant Data
  // missing the integration of the Back-end
  const handleDownload = async () => {
    try {
      const rawData = await fetch('http://localhost:3000/tenant');
      const resData = await rawData.json();
      setTenantReportData(resData);

      setTimeout(() => {
        csvLinkRef?.current?.link?.click();
        handleOpenModal();
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (email && type === 'DOWNLOAD_FULL_REPORT')
      setCalendarState([new Date()]);
  }, [email, type]);

  const language = [
    'Bulgarian',
    'Croatian',
    'Czech',
    'Danish',
    'Dutch',
    'English',
    'Estonian',
    'Finnish',
    'French',
    'German',
    'Greek',
    'Hungarian',
    'Irish',
    'Italian',
    'Latvian',
    'Lithuanian',
    'Maltese',
    'Polish',
    'Portuguese',
    'Romanian',
    'Slovak',
    'Slovenian',
    'Spanish',
    'Swedish',
    'Bangla',
    'Arabic',
    'Hindi',
    'Chinese',
    'Urdu',
  ];

  const handleUploadFileToAPIHandler = async () => {
    try {
      const response = await handleUploadFileToAPI(uploadLink);

      handleOpenModal();
      dispatch(
        callSnackbar({
          message: 'File Uploaded Successfully!',
          type: 'success',
        })
      );
      fetchData();
    } catch (err) {
      console.log(err);
    }
  };

  // error handling
  const [errors, setErrors] = useState({});
  const requiredFields = ['dir', 'country', 'city'];
  const requiredFieldsTest = ['testEmail'];

  const [dir, setDir] = useState(null);
  const [country, setCountry] = useState(null);
  const [city, setCity] = useState(null);
  const [testEmail, setTestEmail] = useState(null);

  const sendNewsLetter = async () => {
    let data = {
      dir,
      country,
      city,
    };
    let err = {};
    for (const key in data) {
      if (!data[key] && requiredFields.includes(key)) {
        err = { ...err, [key]: true };
        console.log('s', data[key], data);
      }
      if (
        Array.isArray(data[key]) &&
        data[key].length === 0 &&
        requiredFields.includes(key)
      ) {
        console.log(data[key]);
        err = { ...err, [key]: true };
      }
    }
    setErrors({ ...err });
    if (Object.keys(err).length === 0) {
      let formData = new FormData();
      formData.append('dir', dir);
      formData.append('country', country);
      formData.append('city', city);
    }
  };

  const sendTestEmail = async () => {
    let data = {
      testEmail,
    };
    let err = {};
    for (const key in data) {
      if (!data[key] && requiredFieldsTest.includes(key)) {
        err = { ...err, [key]: true };
        console.log('s', data[key], data);
      }
      if (
        Array.isArray(data[key]) &&
        data[key].length === 0 &&
        requiredFieldsTest.includes(key)
      ) {
        console.log(data[key]);
        err = { ...err, [key]: true };
      }
    }
    setErrors({ ...err });
    if (Object.keys(err).length === 0) {
      let formData = new FormData();
      formData.append('testEmail', testEmail);
    }
  };

  return (
    <MUIModal open={open}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { md: 550, xs: '100%' },
          bgcolor: 'background.paper',
          boxShadow: 24,
        }}
      >
        {/* HEADER */}
        <Box
          borderBottom="1px solid #b7b7b7"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: '16px 20px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                backgroundColor: type === 'DELETE' ? '#D32F2F' : '#0072D9',
                p: 1,
                mr: 1.5,
                borderRadius: '15px',
                width: '30px',
                height: '30px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {type === 'DELETE' && (
                <>
                  <DeleteOutlinedIcon sx={{ color: '#ffff' }} />
                </>
              )}
              {type === 'SEND_TEST_EMAIL' && (
                <MailOutlinedIcon sx={{ color: '#ffffff' }} />
              )}
              {type === 'UPLOAD' && (
                <FileUploadRoundedIcon sx={{ color: '#ffffff' }} />
              )}
              {type === 'INVITE' && (
                <InsertInvitationIcon sx={{ color: '#ffffff' }} />
              )}
              {type === 'SHARE' && (
                <InsertInvitationIcon sx={{ color: '#ffffff' }} />
              )}
              {type === 'SEND_NEWS_LATER' && (
                <MailOutlinedIcon sx={{ color: '#ffffff' }} />
              )}
              {type === 'ADD_LANGUAGE' && (
                <LanguageIcon sx={{ color: '#ffffff' }} />
              )}
              {type === 'EDIT_LANGUAGE' && (
                <EditRoundedIcon sx={{ color: '#ffffff' }} />
              )}
              {type === 'SEND_WELCOME_EMAIL' && (
                <MailOutlinedIcon sx={{ color: '#ffffff' }} />
              )}
              {type === 'DOWNLOAD_FULL_REPORT' && (
                <AssessmentIcon sx={{ color: '#ffffff' }} />
              )}
            </Box>
            <Typography
              variant="h4"
              fontSize="20px"
              fontWeight="bold"
              sx={{
                color: '#3A3A50',
              }}
            >
              {type === 'DELETE' && 'Remove'}
              {type === 'SEND_TEST_EMAIL' && 'Send a test email'}
              {type === 'UPLOAD' && 'Upload'}
              {type === 'INVITE' && 'Invite provider'}
              {type === 'SHARE' && 'Share booking widget'}
              {type === 'SEND_NEWS_LATER' && 'Send Newsletter'}
              {type === 'ADD_LANGUAGE' && 'Add Language'}
              {type === 'EDIT_LANGUAGE' && 'Edit Language'}
              {type === 'SEND_WELCOME_EMAIL' && 'Send welcome email'}
              {type === 'DOWNLOAD_FULL_REPORT' &&
                `Download Full Report for ${email}`}
            </Typography>
          </Box>
          <Icon sx={{ cursor: 'pointer' }} onClick={handleOpenModal}>
            <CloseIcon />
          </Icon>
        </Box>

        {/* body */}

        <Grid
          sx={{
            p: '0px 20px 10px 20px',
          }}
        >
          {type === 'DELETE' && (
            <>
              <Box
                sx={{
                  width: '100%',
                  height: '200px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{
                    color: '#3A3A50',
                  }}
                >
                  Are you sure you want to remove?
                </Typography>
              </Box>
              <Grid container sx={{ justifyContent: 'end', gap: '15px' }}>
                <CustomButtonPages
                  title="No"
                  isIcon={false}
                  sx={{
                    width: { xs: '100%', sm: 130 },
                    marginBottom: 1,
                    backgroundColor: 'white',
                    color: '#b3a3a6',
                    height: '45px',
                    border: '1px solid #b3a3a6',
                    ':hover': {
                      backgroundColor: 'white',
                      color: '#b3a3a6',
                    },
                  }}
                  onClick={handleOpenModal}
                />
                <CustomButtonPages
                  title="Yes"
                  isIcon={false}
                  del={true}
                  sx={{
                    width: { xs: '100%', sm: 130 },
                    marginBottom: 1,
                    marginLeft: { xs: 0, sm: 2 },
                    height: '45px',
                  }}
                />
              </Grid>
            </>
          )}
          {type === 'SHARE' && (
            <>
              <Grid container spacing={2} marginBottom="2rem">
                <Grid xs={6} item marginTop="1.5rem">
                  <Typography>Service</Typography>
                  <Select
                    sx={{ width: '100%', mt: '5px' }}
                    size="small"
                    name="service"
                    value={bookMarkData.service}
                    onChange={handelChange}
                  >
                    <MenuItem value="select" sx={{ display: 'none' }}>
                      Select
                    </MenuItem>
                    <MenuItem value="fatTansfar">Fat Tanasfar</MenuItem>
                    <MenuItem value="3dmotion">3D Motionn</MenuItem>
                  </Select>
                </Grid>
                <Grid xs={6} item marginTop="1.5rem">
                  <Typography>Location</Typography>
                  <Select
                    sx={{ width: '100%', mt: '5px' }}
                    size="small"
                    name="location"
                    value={bookMarkData.location}
                    onChange={handelChange}
                  >
                    <MenuItem value="select" sx={{ display: 'none' }}>
                      Select
                    </MenuItem>
                    <MenuItem value="dhaka">Dhaka</MenuItem>
                    <MenuItem value="india">India</MenuItem>
                  </Select>
                </Grid>
              </Grid>

              <Grid container spacing={2} marginBottom="2rem">
                <Grid xs={6} item>
                  <Typography>Staff</Typography>
                  <Select
                    sx={{ width: '100%', mt: '5px' }}
                    size="small"
                    name="staff"
                    value={bookMarkData.staff}
                    onChange={handelChange}
                  >
                    <MenuItem value="select" sx={{ display: 'none' }}>
                      Select
                    </MenuItem>
                    <MenuItem value="Alex">AlexGender</MenuItem>
                    <MenuItem value="Hasibul">Hasibul</MenuItem>
                  </Select>
                </Grid>
              </Grid>

              <Grid marginBottom="1.5rem">
                <Box
                  item
                  sx={{
                    background: '#d8d8d8',
                    padding: '13px',
                    textAlign: 'center',
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{ fontSize: '16px', fontWeight: '500' }}
                  >
                    https://demo.booknetic.com/?page_id=2&service=43
                  </Typography>
                </Box>
              </Grid>
              <Grid container sx={{ justifyContent: 'end' }}>
                <Button
                  sx={{
                    width: { xs: '100%', sm: 130 },
                    marginBottom: 1,
                    backgroundColor: 'white',
                    color: '#b3a3a6',
                    height: '45px',
                    border: '1px solid #b3a3a6',
                    ':hover': {
                      backgroundColor: 'white',
                      color: '#b3a3a6',
                    },
                  }}
                  variant="contained"
                  onClick={handleOpenModal}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    width: { xs: '100%', sm: 130 },
                    marginBottom: 1,
                    marginLeft: { xs: 0, sm: 2 },
                    height: '45px',
                  }}
                  variant="contained"
                >
                  Share
                </Button>
              </Grid>
            </>
          )}
          {type === 'INVITE' && (
            <>
              <Grid marginBottom="1.5rem">
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography sx={{ mt: 3, mb: 1, color: '#515151' }}>
                    Message
                  </Typography>
                  <TextField
                    multiline
                    maxRows={2}
                    rows={2}
                    placeholder="Your message here..."
                  ></TextField>
                  <Typography sx={{ mt: 3, mb: 1, color: '#515151' }}>
                    Provider email
                  </Typography>
                  <TextField
                    multiline
                    placeholder="example@email.com"
                  ></TextField>
                </Box>
              </Grid>

              <Grid container sx={{ justifyContent: 'end' }}>
                <Button
                  sx={{
                    width: { xs: '100%', sm: 130 },
                    marginBottom: 1,
                    backgroundColor: 'white',
                    color: '#b3a3a6',
                    height: '45px',
                    border: '1px solid #b3a3a6',
                    ':hover': {
                      backgroundColor: 'white',
                      color: '#b3a3a6',
                    },
                  }}
                  variant="contained"
                  onClick={handleOpenModal}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    width: { xs: '100%', sm: 130 },
                    marginBottom: 1,
                    marginLeft: { xs: 0, sm: 2 },
                    height: '45px',
                  }}
                  variant="contained"
                >
                  Invite
                </Button>
              </Grid>
            </>
          )}
          {type === 'SEND_TEST_EMAIL' && (
            <>
              <Grid
                marginBottom="1.5rem"
                marginTop="1.5rem"
                sx={{ height: '200px' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Grid item xs={12} mb={2}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: '#3A3A50',
                      }}
                    >
                      Email<sapn style={{ color: 'red' }}>*</sapn>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      // value={formData.languageName}
                      onChange={(e) => {
                        setTestEmail(e.target.value);
                        setErrors({ ...errors, testEmail: false });
                      }}
                      id="outlined-basic"
                      size="small"
                      required
                      variant="outlined"
                      placeholder="Example@test.com"
                      sx={{
                        marginBottom: '2rem',
                        width: '100%',
                        fontSize: '14px',
                      }}
                      error={errors.testEmail}
                      helperText={errors.testEmail ? 'Value is missing' : ' '}
                    />
                  </Grid>
                </Box>
              </Grid>

              <Grid container sx={{ justifyContent: 'end', gap: '15px' }}>
                <CustomButtonPages
                  title="Cancel"
                  isIcon={false}
                  onClick={handleOpenModal}
                  sx={{
                    width: { xs: '100%', sm: 130 },
                    marginBottom: 1,
                    backgroundColor: 'white',
                    color: '#b3a3a6',
                    height: '45px',
                    border: '1px solid #b3a3a6',
                    ':hover': {
                      backgroundColor: 'white',
                      color: '#b3a3a6',
                    },
                  }}
                />
                <CustomButtonPages
                  title="SEND"
                  isIcon={false}
                  blue={true}
                  onClick={sendTestEmail}
                  sx={{
                    width: { xs: '100%', sm: 130 },
                    marginBottom: 1,
                    marginLeft: { xs: 0, sm: 2 },
                    height: '45px',
                  }}
                />
              </Grid>
            </>
          )}
          {type === 'UPLOAD' && (
            <>
              <Grid
                marginBottom="1.5rem"
                marginTop="1.5rem"
                sx={{ height: '200px' }}
              >
                {uploadedFile?.current?.files?.length > 0 && (
                  <p>{uploadedFile?.current?.files[0]?.name}</p>
                )}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      border: '#D6D6D6 dashed 2px',
                      cursor: 'pointer',
                    }}
                    onClick={handleUpload}
                    onDrop={(e) => handleDrop(e)}
                    onDragOver={(e) => handleDragOver(e)}
                    onDragEnter={(e) => handleDragEnter(e)}
                    onDragLeave={(e) => handleDragLeave(e)}
                  >
                    <Box>
                      <Typography color={'#D6D6D6'}>
                        Drop your CSV file here or click here to upload
                      </Typography>
                      <Button
                        onMouseOver={() => setDisabled(true)}
                        onMouseLeave={() => setDisabled(false)}
                        onClick={downloadCSVExample(templateCSV)}
                        variant="contained"
                        sx={{
                          width: 'max-content',
                          zIndex: 999999,
                        }}
                      >
                        Download an example
                      </Button>
                      <TextField
                        disabled={disabled}
                        accept={'.csv'}
                        onChange={handleOnChange}
                        id="outlined-basic"
                        required
                        sx={{
                          marginBottom: '1rem',
                          width: '100%',
                          fontSize: '14px',
                          display: 'none',
                        }}
                        // onChange={(e) => getUserImage(e.target.files[0])}
                        inputRef={uploadedFile}
                        type="file"
                      />
                    </Box>
                  </Grid>
                </Box>
              </Grid>

              <Grid
                container
                sx={
                  uploadedFile?.current?.files?.length > 0
                    ? { justifyContent: 'end', marginTop: '60px', gap: '15px' }
                    : { justifyContent: 'end', gap: '15px' }
                }
              >
                <CustomButtonPages
                  title="Cancel"
                  isIcon={false}
                  sx={{
                    width: { xs: '100%', sm: 130 },
                    marginBottom: 1,
                    backgroundColor: 'white',
                    color: '#b3a3a6',
                    height: '45px',
                    border: '1px solid #b3a3a6',
                    ':hover': {
                      backgroundColor: 'white',
                      color: '#b3a3a6',
                    },
                  }}
                  onClick={handleOpenModal}
                />
                <CustomButtonPages
                  title="Upload"
                  isIcon={false}
                  blue={true}
                  onClick={handleUploadFileToAPIHandler}
                  sx={{
                    width: { xs: '100%', sm: 130 },
                    marginBottom: 1,
                    marginLeft: { xs: 0, sm: 2 },
                    height: '45px',
                  }}
                />
              </Grid>
            </>
          )}
          {type === 'SEND_NEWS_LATER' && (
            <>
              <Grid container sx={{ padding: '15px 25px' }}>
                {/** add below this line */}
                <Grid xs={12}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: '#3A3A50',
                      }}
                    >
                      Select directions<sapn style={{ color: 'red' }}>*</sapn>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      options={directions}
                      disableCloseOnSelect
                      size="small"
                      getOptionLabel={(option) => option}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>{option}</li>
                      )}
                      onChange={(e, data) => {
                        setDir(data);
                        setErrors({ ...errors, dir: false });
                      }}
                      style={{}}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={errors.dir}
                          helperText={errors.dir ? 'Value is missing' : ' '}
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <Grid xs={12}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">
                      Country<sapn style={{ color: 'red' }}>*</sapn>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      options={countries}
                      disableCloseOnSelect
                      size="small"
                      getOptionLabel={(option) => option}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>{option}</li>
                      )}
                      style={{}}
                      onChange={(e, data) => {
                        setDir(data);
                        setErrors({ ...errors, country: false });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={errors.country}
                          helperText={errors.country ? 'Value is missing' : ' '}
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <Grid xs={12}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">
                      City<sapn style={{ color: 'red' }}>*</sapn>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      options={cities}
                      disableCloseOnSelect
                      size="small"
                      getOptionLabel={(option) => option}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>{option}</li>
                      )}
                      style={{}}
                      onChange={(e, data) => {
                        setDir(data);
                        setErrors({ ...errors, city: false });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={errors.city}
                          helperText={errors.city ? 'Value is missing' : ' '}
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <Grid xs={12} sx={{ marginTop: 4 }}>
                  <Typography variant="subtitle2" style={{ fontWeight: 500 }}>
                    Numbers of subscribed users that will recieve the email
                  </Typography>
                  <Typography variant="h6">349</Typography>
                </Grid>
              </Grid>
              <Grid container sx={{ justifyContent: 'end', gap: '15px' }}>
                <CustomButtonPages
                  title="Cancel"
                  isIcon={false}
                  onClick={handleOpenModal}
                  sx={{
                    width: { xs: '100%', sm: 130 },
                    marginBottom: 1,
                    backgroundColor: 'white',
                    color: '#b3a3a6',
                    height: '45px',
                    border: '1px solid #b3a3a6',
                    ':hover': {
                      backgroundColor: 'white',
                      color: '#b3a3a6',
                    },
                  }}
                />
                <CustomButtonPages
                  title="SEND"
                  isIcon={false}
                  blue={true}
                  onClick={sendNewsLetter}
                  sx={{
                    width: { xs: '100%', sm: 130 },
                    marginBottom: 1,
                    marginLeft: { xs: 0, sm: 2 },
                    height: '45px',
                  }}
                />
              </Grid>
            </>
          )}
          {type === 'ADD_LANGUAGE' && (
            <Grid container sx={{ padding: '15px 0px' }}>
              <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 3 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: '#3A3A50',
                    }}
                  >
                    Language Name:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    forcePopupIcon={false}
                    multiple
                    open={lanOpen}
                    onOpen={() => {
                      if (inputValue) {
                        setLanOpen(true);
                      }
                    }}
                    onClose={() => setLanOpen(false)}
                    inputValue={inputValue}
                    onInputChange={(e, value, reason) => {
                      setInputValue(value);

                      if (!value) {
                        setLanOpen(false);
                      }
                    }}
                    id="checkboxes-tags-demo"
                    options={language}
                    disableCloseOnSelect
                    placeholder="Example: English, swedish"
                    size="small"
                    getOptionLabel={(option) => option}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>{option}</li>
                    )}
                    style={{}}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={'Example: English, swedish'}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid xs={12} sm={6}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: '#3A3A50',
                    }}
                  >
                    Short Form:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={formData.shortForm}
                    onChange={(e) =>
                      handleFormData('shortForm', e.target.value)
                    }
                    id="outlined-basic"
                    size="small"
                    required
                    variant="outlined"
                    placeholder="Example: en, sv, esp"
                    sx={{
                      marginBottom: '2rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                  />
                </Grid>
              </Grid>

              <Grid xs={12}>
                <RadioGroup
                  defaultValue="LTR"
                  onChange={(e) => handleFormData('direction', e.target.value)}
                >
                  <FormControlLabel
                    sx={{
                      color: '#3A3A50',
                    }}
                    value="LTR"
                    control={<Radio />}
                    label="LTR - Left to Right"
                  />
                  <FormControlLabel
                    sx={{
                      color: '#3A3A50',
                    }}
                    value="RTL"
                    control={<Radio />}
                    label="RTL - Right to Left"
                  />
                </RadioGroup>
              </Grid>
              {/* button */}
              <Grid container sx={{ justifyContent: 'end' }}>
                <Button
                  sx={{
                    width: { xs: '100%', sm: 130 },
                    marginBottom: 1,
                    backgroundColor: 'white',
                    color: '#b3a3a6',
                    height: '45px',
                    border: '1px solid #b3a3a6',
                    ':hover': {
                      backgroundColor: 'white',
                      color: '#b3a3a6',
                    },
                  }}
                  variant="contained"
                  onClick={handleOpenModal}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    width: { xs: '100%', sm: 130 },
                    marginBottom: 1,
                    marginLeft: { xs: 0, sm: 2 },
                    height: '45px',
                  }}
                  variant="contained"
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          )}
          {type === 'EDIT_LANGUAGE' && (
            <Grid container sx={{ padding: '15px 0px' }}>
              <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 3 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: '#3A3A50',
                    }}
                  >
                    Language Name:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    forcePopupIcon={false}
                    multiple
                    open={lanOpen}
                    onOpen={() => {
                      if (inputValue) {
                        setLanOpen(true);
                      }
                    }}
                    onClose={() => setLanOpen(false)}
                    inputValue={inputValue}
                    onInputChange={(e, value, reason) => {
                      setInputValue(value);

                      if (!value) {
                        setLanOpen(false);
                      }
                    }}
                    id="checkboxes-tags-demo"
                    options={language}
                    disableCloseOnSelect
                    placeholder="Example: English, swedish"
                    size="small"
                    getOptionLabel={(option) => option}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>{option}</li>
                    )}
                    style={{}}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={'Example: English, swedish'}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid xs={12} sm={6}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: '#3A3A50',
                    }}
                  >
                    Short Form:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={formData.shortForm}
                    onChange={(e) =>
                      handleFormData('shortForm', e.target.value)
                    }
                    id="outlined-basic"
                    size="small"
                    required
                    variant="outlined"
                    placeholder="Example: en, sv, esp"
                    sx={{
                      marginBottom: '2rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                  />
                </Grid>
              </Grid>

              <Grid xs={12}>
                <RadioGroup
                  defaultValue="LTR"
                  onChange={(e) => handleFormData('direction', e.target.value)}
                >
                  <FormControlLabel
                    sx={{
                      color: '#3A3A50',
                    }}
                    value="LTR"
                    control={<Radio />}
                    label="LTR - Left to Right"
                  />
                  <FormControlLabel
                    sx={{
                      color: '#3A3A50',
                    }}
                    value="RTL"
                    control={<Radio />}
                    label="RTL - Right to Left"
                  />
                </RadioGroup>
              </Grid>
              {/* button */}
              <Grid container sx={{ justifyContent: 'end' }}>
                <Button
                  sx={{
                    width: { xs: '100%', sm: 130 },
                    marginBottom: 1,
                    backgroundColor: 'white',
                    color: '#b3a3a6',
                    height: '45px',
                    border: '1px solid #b3a3a6',
                    ':hover': {
                      backgroundColor: 'white',
                      color: '#b3a3a6',
                    },
                  }}
                  variant="contained"
                  onClick={handleOpenModal}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    width: { xs: '100%', sm: 130 },
                    marginBottom: 1,
                    marginLeft: { xs: 0, sm: 2 },
                    height: '45px',
                  }}
                  variant="contained"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          )}
          {type === 'DOWNLOAD_FULL_REPORT' && (
            <>
              <Box
                sx={{
                  width: '100%',
                  height: '300px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '30px 0',
                }}
              >
                <Grid item xs={12} sx={{ marginBottom: 1 }}>
                  <Typography fontWeight="bold" variant="subtitle2">
                    Choose Report Range
                  </Typography>
                </Grid>
                <Calendar
                  className={classes.calendar}
                  value={calendarState}
                  onChange={setCalendarState}
                  range
                  rangeHover
                />
              </Box>
              <Grid container sx={{ justifyContent: 'end' }}>
                <Button
                  sx={{
                    width: { xs: '100%', sm: 130 },
                    marginBottom: 1,
                    backgroundColor: 'white',
                    color: '#b3a3a6',
                    height: '45px',
                    border: '1px solid #b3a3a6',
                    ':hover': {
                      backgroundColor: 'white',
                      color: '#b3a3a6',
                    },
                  }}
                  variant="contained"
                  onClick={handleOpenModal}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    width: { xs: '100%', sm: 130 },
                    marginBottom: 1,
                    marginLeft: { xs: 0, sm: 2 },
                    height: '45px',
                  }}
                  variant="contained"
                  onClick={handleDownload}
                >
                  Download
                </Button>
                <CSVLink
                  ref={csvLinkRef}
                  headers={csvHeader}
                  data={tenantReportData}
                ></CSVLink>
              </Grid>
            </>
          )}
          {type === 'SEND_WELCOME_EMAIL' && (
            <>
              <Box
                sx={{
                  width: '100%',
                  height: '300px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <PersonIcon sx={{ height: 100, width: 100 }} />
                <Typography sx={{ fontSize: 22, color: '#3A3A50' }}>
                  Send welcome email
                </Typography>
                <Typography
                  sx={{
                    color: '#3A3A50',
                  }}
                >
                  The email includes instructions on how to log in. We will send
                  it to:
                </Typography>
                <Typography>{email}</Typography>
              </Box>
              <Grid container sx={{ justifyContent: 'end', gap: '15px' }}>
                <CustomButtonPages
                  title="Cancel"
                  isIcon={false}
                  onClick={handleOpenModal}
                  sx={{
                    width: { xs: '100%', sm: 130 },
                    marginBottom: 1,
                    backgroundColor: 'white',
                    color: '#b3a3a6',
                    height: '45px',
                    border: '1px solid #b3a3a6',
                    ':hover': {
                      backgroundColor: 'white',
                      color: '#b3a3a6',
                    },
                  }}
                />
                <CustomButtonPages
                  title="SEND"
                  isIcon={false}
                  blue={true}
                  onClick={() => {
                    sendWelcomeEmail(email).then((res) => {
                      if (res.response.status === 200) {
                        handleOpenModal();
                      }
                    });
                  }}
                  sx={{
                    width: { xs: '100%', sm: 130 },
                    marginBottom: 1,
                    marginLeft: { xs: 0, sm: 2 },
                    height: '45px',
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </MUIModal>
  );
};

export default Modal;
