import { Grid } from '@mui/material';
import Stripe from './Stripe';
import Paypal from './Paypal';
import LocalPayment from './LocalPayment';

const PaymentMethodsDisplay = ({ page }) => {
  return (
    <Grid container xs={12} sx={{ height: 'fit-content' }}>
      {page === 'Stripe' && <Stripe />}
      {page === 'Paypal' && <Paypal />}
      {page === 'Local payment' && <LocalPayment />}
    </Grid>
  );
};

export default PaymentMethodsDisplay;
