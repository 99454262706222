import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useState } from 'react';
import Switch from '@mui/material/Switch';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import Modal from '@mui/material/Modal';
import './notification.css';
import ShareModal from '../../components/Modal';
import { tagsData } from './tagsData';
import {
  editCSU,
  editPR,
  editSUB,
  editSUC,
  editUnSub,
  getNotificationsList,
} from '../../Api/Admin/notifications';
import QuillEditor from '../../components/QuillEditor';
import { useRef } from 'react';
import SaveSlideBar from '../../components/SaveSlideBar';
import { useLayoutEffect } from 'react';
import CustomButtonPages from '../../components/CustomButtonPages';
import CustomTooltip from '../../components/CustomTooltip';

const Notification = () => {
  const templateHeadings = [
    'CONFIRM SIGN-UP',
    'SIGN-UP COMPLETED',
    'SUBSCRIBED',
    'UNSUBSCRIBED',
    'PAYMENT RECEIVED',
  ];
  const [notifications, setNotifications] = useState();
  const [isSaveBar, setIsSaveBar] = useState(false);
  const [quillHtml, setQuillHtml] = useState('');
  const [quillRange, setQuillRange] = useState(0);
  const [templateId, setTemplateId] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [currentSubject, setCurrentSubject] = useState('');
  const [enabledNotifications, SetEnabledNotifications] = useState({
    CSU: 0,
    SUC: 0,
    SUB: 0,
    UnSUB: 0,
    PR: 0,
  });

  const quillRef = useRef();

  const changeTemplateId = (id) => {
    setTemplateId(id);
  };

  const handleOpenModal = () => {
    setOpenModal(false);
  };

  // API

  const fetchNotifications = () => {
    const data = getNotificationsList().then((res) => {
      setNotifications(res.data[0]);
      return res.data[0];
    });
    return data;
  };

  useLayoutEffect(() => {
    fetchNotifications().then((noti) => {
      setQuillHtml(noti?.CSU_Body);
      setCurrentSubject(noti?.CSU_Subject);
      SetEnabledNotifications({
        CSU: noti.CSU,
        SUC: noti.SUC,
        SUB: noti.SUB,
        UnSUB: noti.UnSUB,
        PR: noti.PR,
      });
    });
  }, []);

  // handlers
  const handleSave = () => {
    if (templateId === 1) {
      editCSU({
        ...enabledNotifications,
        CSU_Subject: currentSubject,
        CSU_Body: quillHtml,
      }).then((res) => {
        if (res.status === 200) {
          setIsSaveBar(false);
          fetchNotifications();
        }
      });
    }
    if (templateId === 2) {
      editSUC({
        ...enabledNotifications,
        SUC_Subject: currentSubject,
        SUC_Body: quillHtml,
      }).then((res) => {
        if (res.status === 200) {
          setIsSaveBar(false);
          fetchNotifications();
        }
      });
    }
    if (templateId === 3) {
      editSUB({
        ...enabledNotifications,
        SUB_Subject: currentSubject,
        SUB_Body: quillHtml,
      }).then((res) => {
        if (res.status === 200) {
          setIsSaveBar(false);
          fetchNotifications();
        }
      });
    }
    if (templateId === 4) {
      editUnSub({
        ...enabledNotifications,
        UnSUB_Subject: currentSubject,
        UnSUB_Body: quillHtml,
      }).then((res) => {
        if (res.status === 200) {
          setIsSaveBar(false);
          fetchNotifications();
        }
      });
    }
    if (templateId === 5) {
      editPR({
        ...enabledNotifications,
        PR_Subject: currentSubject,
        PR_Body: quillHtml,
      }).then((res) => {
        if (res.status === 200) {
          setIsSaveBar(false);
          fetchNotifications();
        }
      });
    }
  };

  const handleCancel = () => {
    // restting editor body and the subject
    switch (templateId) {
      case 5:
        setQuillHtml(notifications?.PR_Body);
        setCurrentSubject(notifications?.PR_Subject);
        break;

      case 4:
        setQuillHtml(notifications?.UnSUB_Body);
        setCurrentSubject(notifications?.UnSUB_Subject);
        break;

      case 3:
        setQuillHtml(notifications?.SUB_Body);
        setCurrentSubject(notifications?.SUB_Subject);
        break;

      case 2:
        setQuillHtml(notifications?.SUC_Body);
        setCurrentSubject(notifications?.SUC_Subject);
        break;
      case 1:
        setQuillHtml(notifications?.CSU_Body);
        setCurrentSubject(notifications?.CSU_Subject);
        break;

      default:
        break;
    }

    // restting switchs
    SetEnabledNotifications({
      CSU: notifications.CSU,
      SUC: notifications.SUC,
      SUB: notifications.SUB,
      UnSUB: notifications.UnSUB,
      PR: notifications.PR,
    });
    setIsSaveBar(false);
  };

  return (
    <Grid
      item
      container
      sx={{
        minWidth: { xs: '100%' },
        paddingLeft: 4,
        paddingRight: 4,
      }}
    >
      <Modal open={openModal}>
        <ShareModal
          handleOpenModal={handleOpenModal}
          type={'SEND_TEST_EMAIL'}
          open={openModal}
        />
      </Modal>
      {/*------------------ Teanants Heading---------------------- */}
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          flexWrap: 'wrap',
          paddingBottom: '30px',
          borderBottom: { xs: '1px solid #e3eaf3' },
        }}
      >
        <Typography
          sx={{ marginTop: 5, marginBottom: 1, fontSize: 28, color: '#3A3A50' }}
        >
          Email Notifications
        </Typography>
        <Grid
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <CustomButtonPages
            isIcon={true}
            icon={<ForwardToInboxIcon />}
            tooltipTitle="Send Test Email"
            onClick={() => setOpenModal(true)}
          />
        </Grid>
      </Grid>
      {/* ---------MAIN BODY---------------------- */}
      <Grid container sx={{ paddingY: 4 }}>
        {/* -------Toggles------------ */}
        <Grid
          item
          xs={12}
          sm={3}
          sx={{
            backgroundColor: 'white !important',
            boxShadow: '0 0 30px 0 rgb(0 0 0 / 5%)',
          }}
        >
          <Grid
            item
            xs={12}
            sx={{ borderBottom: '1px solid lightgrey', paddingX: 2 }}
          >
            <Typography
              variant="subtitle2"
              sx={{ color: '#3A3A50', fontWeight: '600', paddingY: 2 }}
            >
              EMAIL
            </Typography>
          </Grid>
          <Grid sx={{ paddingX: 2 }}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  color: '#6c70dc',
                  fontSize: '1rem',
                  paddingTop: 2,
                  paddingBottom: 1,
                }}
              >
                Notifications
              </Typography>
            </Grid>
            <Grid
              className={templateId == 1 ? 'notificationSelected' : ''}
              item
              xs={12}
              sx={{
                color: '#828F9A',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                border: '1px solid #e3eaf3',
                paddingX: 2,
                paddingY: 1,
                marginY: 1,
              }}
              onClick={() => {
                changeTemplateId(1);
                setQuillHtml(notifications?.CSU_Body);
                setCurrentSubject(notifications?.CSU_Subject);
                setIsSaveBar(false);
              }}
            >
              <Typography variant="subtitle2">Confirm Sign-up</Typography>
              <Switch
                size="small"
                checked={enabledNotifications.CSU}
                onChange={(e) => {
                  SetEnabledNotifications((prev) => {
                    return { ...prev, CSU: e.target.checked };
                  });
                  setIsSaveBar(true);
                }}
                sx={{ marginLeft: 2 }}
              />
            </Grid>
            <Grid
              className={templateId == 2 ? 'notificationSelected' : ''}
              item
              xs={12}
              sx={{
                color: '#828F9A',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                border: '1px solid #e3eaf3',
                paddingX: 2,
                paddingY: 1,
                marginY: 1,
              }}
              onClick={() => {
                changeTemplateId(2);
                setQuillHtml(notifications?.SUC_Body);
                setCurrentSubject(notifications?.SUC_Subject);
                setIsSaveBar(false);
              }}
            >
              <Typography variant="subtitle2">Sign-up completed</Typography>
              <Switch
                size="small"
                checked={enabledNotifications.SUC}
                onChange={(e) => {
                  SetEnabledNotifications((prev) => {
                    return { ...prev, SUC: e.target.checked };
                  });
                  setIsSaveBar(true);
                }}
                sx={{ marginLeft: 2, color: '#6c70dc !important' }}
              />
            </Grid>
            <Grid
              className={templateId == 3 ? 'notificationSelected' : ''}
              item
              xs={12}
              sx={{
                color: '#828F9A',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                border: '1px solid #e3eaf3',
                paddingX: 2,
                paddingY: 1,
                marginY: 1,
              }}
              onClick={() => {
                changeTemplateId(3);
                setQuillHtml(notifications?.SUB_Body);
                setCurrentSubject(notifications?.SUB_Subject);
                setIsSaveBar(false);
              }}
            >
              <Typography variant="subtitle2">Subscribed</Typography>
              <Switch
                size="small"
                checked={enabledNotifications.SUB}
                onChange={(e) => {
                  SetEnabledNotifications((prev) => {
                    return { ...prev, SUB: e.target.checked };
                  });
                  setIsSaveBar(true);
                }}
                sx={{ marginLeft: 2 }}
              />
            </Grid>
            <Grid
              className={templateId == 4 ? 'notificationSelected' : ''}
              item
              xs={12}
              sx={{
                color: '#828F9A',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                border: '1px solid #e3eaf3',
                paddingX: 2,
                paddingY: 1,
                marginY: 1,
              }}
              onClick={() => {
                changeTemplateId(4);
                setQuillHtml(notifications?.UnSUB_Body);
                setCurrentSubject(notifications?.UnSUB_Subject);
                setIsSaveBar(false);
              }}
            >
              <Typography variant="subtitle2">Unsubscribed</Typography>
              <Switch
                size="small"
                checked={enabledNotifications.UnSUB}
                onChange={(e) => {
                  SetEnabledNotifications((prev) => {
                    return { ...prev, UnSUB: e.target.checked };
                  });
                  setIsSaveBar(true);
                }}
                sx={{ marginLeft: 2 }}
              />
            </Grid>
            <Grid
              className={templateId == 5 ? 'notificationSelected' : ''}
              item
              xs={12}
              sx={{
                color: '#828F9A',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                border: '1px solid #e3eaf3',
                paddingX: 2,
                paddingY: 1,
                marginY: 1,
              }}
              onClick={() => {
                changeTemplateId(5);
                setQuillHtml(notifications?.PR_Body);
                setCurrentSubject(notifications?.PR_Subject);
                setIsSaveBar(false);
              }}
            >
              <Typography variant="subtitle2">Payment received</Typography>
              <Switch
                size="small"
                checked={enabledNotifications.PR}
                onChange={(e) => {
                  SetEnabledNotifications((prev) => {
                    return { ...prev, PR: e.target.checked };
                  });
                  setIsSaveBar(true);
                }}
                sx={{ marginLeft: 2 }}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* -----Editor------------- */}
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            paddingX: { xs: 0, sm: 2 },
            boxShadow: '0 0 30px 0 rgb(0 0 0 / 5%)',
          }}
        >
          <Grid sx={{ marginTop: { xs: 2, sm: 0 }, backgroundColor: 'white' }}>
            <Grid
              item
              xs={12}
              sx={{ borderBottom: '1px solid lightgrey', paddingX: 2 }}
            >
              <Typography
                variant="subtitle2"
                sx={{ color: '#3A3A50', fontWeight: '600', paddingY: 2 }}
              >
                {templateId
                  ? templateHeadings[templateId - 1]
                  : templateHeadings[0]}
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sx={{ overflowX: 'hidden', paddingX: 2 }}
            >
              <Grid item xs={12} sx={{ marginTop: 3 }}>
                <Typography
                  variant="subtitle2"
                  sx={{ marginBottom: 1, color: '#3A3A50' }}
                >
                  Email Subject
                </Typography>
                <input
                  style={{
                    width: '100%',
                    border: '1px solid #E3EAF3',
                    height: '46px',
                    fontSize: '14px',
                    color: '#292D32',
                    outline: 'none',
                    borderRadius: '2px',
                    paddingLeft: '10px',
                  }}
                  value={currentSubject}
                  onChange={(e) => {
                    setCurrentSubject(e.target.value);
                    setIsSaveBar(true);
                  }}
                ></input>
              </Grid>
              <Grid sx={{ paddingBottom: 3 }}>
                <Typography
                  variant="subtitle2"
                  sx={{ paddingTop: 3, paddingBottom: 1, color: '#3A3A50' }}
                >
                  Email Body
                </Typography>
                <QuillEditor
                  quillRef={quillRef}
                  onChange={({ html }) => {
                    setQuillHtml(html);
                    setIsSaveBar(true);
                  }}
                  onBlur={(prevSelection) => {
                    setQuillRange(prevSelection);
                  }}
                  value={quillHtml}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* --------Right Side----------------- */}
        <Grid
          item
          xs={12}
          sm={3}
          sx={{
            backgroundColor: 'white !important',
            boxShadow: '0 0 30px 0 rgb(0 0 0 / 5%)',
          }}
        >
          <Grid
            item
            xs={12}
            sx={{ borderBottom: '1px solid lightgrey', paddingX: 2 }}
          >
            <Typography
              variant="subtitle2"
              sx={{ color: '#3A3A50', fontWeight: '600', paddingY: 2 }}
            >
              SHORT TAGS
            </Typography>
          </Grid>
          <Grid sx={{ paddingX: 2, overflowY: 'scroll', height: 550 }}>
            {tagsData.map((cate) => {
              return (
                <>
                  <Typography
                    sx={{
                      color: '#6c70dc',
                      fontSize: '1rem',
                      paddingTop: 2,
                      paddingBottom: 1,
                    }}
                  >
                    {cate.header}
                  </Typography>

                  {cate.values.map((tag) => {
                    return (
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            color: '#9ba6af',
                            fontSize: '.875rem',
                            paddingTop: 2,
                            paddingBottom: 1,
                            cursor: 'pointer',
                            ':hover': { color: 'black' },
                          }}
                          onClick={() => {
                            quillRef.current.editor.insertText(quillRange, tag);
                          }}
                        >
                          {tag}
                        </Typography>
                      </Grid>
                    );
                  })}
                </>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      <SaveSlideBar
        handleCancel={handleCancel}
        handleSave={handleSave}
        isSaveBar={isSaveBar}
        setIsSaveBar={setIsSaveBar}
        type={'NOTIFICATIONS'}
      />
    </Grid>
  );
};

export default Notification;
