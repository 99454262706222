import { useEffect, useState } from 'react';
import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import SaveSlideBar from '../../SaveSlideBar';
import { editSettings } from '../../../Api/Admin/settings';
import { useLayoutEffect } from 'react';

const currencies = ['US Dollar ( $ )', 'Euro (€)'];
const currencyFormats = ['$100', '$ 100', '100$', '100 $'];
const priceNumberFormats = ['45 000.00', '45,000.00', '45 000,00', '45.000,00'];
const priceNumbersOfDecimals = [
  '100',
  '100.0',
  '100.00',
  '100.000',
  '100.0000',
];

const General = ({ currentSettings, fetchCurrentSettings }) => {
  const [isSaveBar, setIsSaveBar] = useState(false);
  const mediaQuery967 = useMediaQuery('(min-width:967px)');
  const [selectedValues, setSelectedValues] = useState({
    currency: '',
    currencySymbol: '$',
    currencyFormat: '',
    priceNumberFormat: '',
    priceNumberOfDecimals: '',
  });

  const handleSelectedValue = (key, selectedValue) => {
    setSelectedValues({ ...selectedValues, [key]: selectedValue });
    setIsSaveBar(true);
  };

  // API

  // populating the data
  useLayoutEffect(() => {
    setSelectedValues(currentSettings);
  }, []);

  // click handlers
  const handleSave = () => {
    editSettings(selectedValues).then((res) => {
      fetchCurrentSettings();
      if (res.status === 200) {
        setIsSaveBar(false);
      }
    });
  };

  const handleCancel = () => {
    setSelectedValues(currentSettings);
    setIsSaveBar(false);
  };

  return (
    <>
      <Grid
        xs={12}
        sx={{
          marginTop: { xs: 9, sm: 14 },
          backgroundColor: 'white',
        }}
      >
        <Grid
          sx={{
            padding: { md: '15px 25px', xs: '15px 15px' },
            borderBottom: '1px solid #e3eaf3',
          }}
        >
          <Typography
            sx={{ fontSize: '18px', fontWeight: 500, color: '#3A3A50' }}
          >
            Payment Settings
          </Typography>
        </Grid>
        <Grid container sx={{ padding: { md: '15px 25px', xs: '15px 15px' } }}>
          <Grid
            xs={12}
            sm={mediaQuery967 ? 4 : 12}
            sx={{ paddingRight: { sm: 1 } }}
          >
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography
                variant="subtitle2"
                sx={{
                  color: '#3A3A50',
                }}
              >
                Currency
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={currencies}
                value={selectedValues?.currency}
                sx={{ marginBottom: '2rem', fontSize: '14px' }}
                onChange={(e, selectedValue) =>
                  handleSelectedValue('currency', selectedValue)
                }
                renderInput={(params) => (
                  <TextField {...params} label="Select" size="small" />
                )}
              />
            </Grid>
          </Grid>

          <Grid
            xs={12}
            sm={mediaQuery967 ? 4 : 12}
            sx={{ paddingRight: { sm: 1 } }}
          >
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography
                variant="subtitle2"
                sx={{
                  color: '#3A3A50',
                }}
              >
                Currency symbol:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={selectedValues?.currencySymbol}
                onChange={(e) =>
                  handleSelectedValue('currencySymbol', e.target.value)
                }
                id="outlined-basic"
                size="small"
                required
                variant="outlined"
                sx={{ marginBottom: '2rem', width: '100%', fontSize: '14px' }}
              />
            </Grid>
          </Grid>

          <Grid xs={12} sm={mediaQuery967 ? 4 : 12}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography
                variant="subtitle2"
                sx={{
                  color: '#3A3A50',
                }}
              >
                Currency format:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={currencyFormats}
                value={selectedValues?.currencyFormat}
                sx={{ marginBottom: '2rem', fontSize: '14px' }}
                onChange={(e, selectedValue) =>
                  handleSelectedValue('currencyFormat', selectedValue)
                }
                renderInput={(params) => (
                  <TextField {...params} label="Select" size="small" />
                )}
              />
            </Grid>
          </Grid>

          <Grid
            xs={12}
            sm={mediaQuery967 ? 6 : 12}
            sx={{ paddingRight: { sm: 1 } }}
          >
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography
                variant="subtitle2"
                sx={{
                  color: '#3A3A50',
                }}
              >
                Price number format:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={priceNumberFormats}
                value={selectedValues?.priceNumberFormat}
                sx={{ marginBottom: '2rem', fontSize: '14px' }}
                onChange={(e, selectedValue) =>
                  handleSelectedValue('priceNumberFormat', selectedValue)
                }
                renderInput={(params) => (
                  <TextField {...params} label="Select" size="small" />
                )}
              />
            </Grid>
          </Grid>

          <Grid xs={12} sm={mediaQuery967 ? 6 : 12}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography
                variant="subtitle2"
                sx={{
                  color: '#3A3A50',
                }}
              >
                Price number of decimals:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={priceNumbersOfDecimals}
                value={selectedValues?.priceNumberOfDecimals}
                sx={{ marginBottom: '2rem', fontSize: '14px' }}
                onChange={(e, selectedValue) =>
                  handleSelectedValue('priceNumberOfDecimals', selectedValue)
                }
                renderInput={(params) => (
                  <TextField {...params} label="Select" size="small" />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SaveSlideBar
        handleCancel={handleCancel}
        handleSave={handleSave}
        isSaveBar={isSaveBar}
        setIsSaveBar={setIsSaveBar}
      />
    </>
  );
};

export default General;
