import React, { useMemo } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import ImageUploader from 'quill-image-uploader';
import { uploadImage } from '../../Api/Admin/news';
import './styles.scss';
//  registering custom modules
Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageUploader', ImageUploader);
var Font = Quill.import('formats/font');
Font.whitelist = [
  'Roboto',
  'Raleway',
  'Montserrat',
  'Lato',
  'Rubik',
  'impact',
  'courier',
  'comic',
];
Quill.register(Font, true);

function QuillEditor({ onChange, value, quillRef, onBlur }) {
  const handleOnChange = (content) => {
    if (onChange) {
      onChange({
        html: content,
      });
    }
  };

  const formats = [
    'header',
    'font',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'background',
    'code',
    'script',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
  ];

  // use literal object as modules props, it will trigger component rerender , use useMemo hook to memorize modules prop to fix it.
  const modules = useMemo(() => {
    return {
      toolbar: [
        [{ header: '1' }, { header: '2' }, { font: Font.whitelist }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' },
        ],
        ['link', 'image', 'video'],
        [{ script: 'sub' }, { script: 'super' }],
      ],
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      },
      imageResize: {
        displayStyles: {
          backgroundColor: 'black',
          border: 'none',
          color: 'white',
        },
        modules: ['Resize', 'DisplaySize', 'Toolbar'],
      },
      imageUploader: {
        upload: (file) => {
          return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('image', file);
            console.log(file);
            console.log(process.env.REACT_APP_ENV);

            uploadImage(formData).then((res) => {
              console.log(res);
              resolve(
                (process.env.REACT_APP_ENV === 'TEST'
                  ? 'https://t-backend.upnize.com/'
                  : 'https://backend.upnize.com/') + res.data.src
              );
            });
            // .then((response) => response.json())
            // .then((result) => {
            //   console.log(result);
            //   resolve(result.data.src);
            // })
            // .catch((error) => {
            //   reject("Upload failed");
            //   console.error("Error:", error);
            // });
          });
        },
      },
    };
  }, []);

  return (
    <ReactQuill
      ref={quillRef}
      modules={modules}
      value={value}
      onChange={handleOnChange}
      onBlur={onBlur}
      formats={formats}
    />
  );
}
export default QuillEditor;
