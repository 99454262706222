import { UpnizeBackend } from "../Axios";

export const getPaymentList = async (rows, page, queries) => {
  let response = await UpnizeBackend.get(
    `/payments/list?Rows=${rows}&page=${page}${queries ? queries : ""}`
  );
  console.log(response.data);
  return response;
};

export const getPaymentById = async (id) => {
  let response = await UpnizeBackend.get(`/payments/byId/${id}`);
  console.log(response.data);
  return response;
};

export const addPayment = async (body) => {
  let response = await UpnizeBackend.post(`/payments/add`, body);
  console.log(response.data);
  return response;
};

export const editPaymentById = async (body, id) => {
  let response = await UpnizeBackend.put(`/payments/edit/${id}`, body);
  console.log(response.data);
  return response;
};

export const deletePaymentById = async (id) => {
  let response = await UpnizeBackend.delete(`/payments/delete/byId/${id}`);
  console.log(response.data);
  return response;
};
