import { UpnizeBackend } from "../Axios";

export const getPlanList = async (rows, page, searchQuery) => {
  let response = await UpnizeBackend.get(
    `/plans/list?Rows=${rows}&page=${page}${
      searchQuery ? `&keyword=${searchQuery}` : ""
    }`
  );
  console.log(response.data);
  return response;
};

export const getPlanById = async (id) => {
  let response = await UpnizeBackend.get(`/plans/byId/${id}`);
  console.log(response.data);
  return response;
};

export const addPlan = async (body) => {
  let response = await UpnizeBackend.post(`/plans/add`, body);
  console.log(response.data);
  return response;
};

export const editPlanById = async (body, id) => {
  let response = await UpnizeBackend.put(`/plans/edit/${id}`, body);
  console.log(response.data);
  return response;
};

export const deletePlanById = async (id) => {
  let response = await UpnizeBackend.delete(`/plans/delete/byid/${id}`);
  console.log(response.data);
  return response;
};
