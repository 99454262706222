import { Grid, Switch, Typography } from '@mui/material';
import { useState } from 'react';

const Permissions = ({ permissions, setPermissions, ...props }) => {
  const parmissions = [
    { parmissionName: 'Dashboard', code: 'DSH' },
    { parmissionName: 'Reports', code: 'RP' },
    {
      parmissionName: 'Appointments',
      code: 'APP',
      options: [{ optionName: 'Receving appointments', code: 'R_APP' }],
    },
    { parmissionName: 'Calendar', code: 'CL' },
    { parmissionName: 'Payments', code: 'PYM' },
    { parmissionName: 'Customers', code: 'CUS' },
    {
      parmissionName: 'Services',
      options: [{ optionName: 'Recurring', code: 'R_SV' }],
      code: 'SV',
    },
    { parmissionName: 'Staff', code: 'ST' },
    { parmissionName: 'Reviews', code: 'RV' },
    { parmissionName: 'Locations', code: 'LOC' },
    { parmissionName: 'Coupons', code: 'CPN' },
    { parmissionName: 'Gift card', code: 'GFT' },
    { parmissionName: 'Invoices', code: 'INV' },
    { parmissionName: 'Email notifications', code: 'EML' },
    { parmissionName: 'SMS notifications', code: 'SMS' },
    { parmissionName: 'Workflows', code: 'WRK' },
    { parmissionName: 'Role manager', code: 'ROL' },
    {
      parmissionName: 'Settings module',
      code: 'SET',
      options: [
        { optionName: 'General settings > General', code: 'G_SET_G' },
        {
          optionName: 'General settings > Appointment statuses',
          code: 'G_SET_AS',
        },
        { optionName: 'General settings > Waiting list', code: 'G_SET_WL' },
        { optionName: 'Front - end panels > Booking widget', code: 'FBW_SET' },
        { optionName: 'Front - end panels > Booking steps', code: 'FBS_SET' },
        { optionName: 'Front - end panels > Customer panel', code: 'FCP_SET' },
        { optionName: 'Front - end panels > Rating', code: 'FR_SET' },
        { optionName: 'Payment settings > General', code: 'PG_SET' },
        { optionName: 'Payment settings > Payment method', code: 'PM_SET' },
        {
          optionName: 'Company details',
          code: 'CD',
        },
        {
          optionName: 'Business hours',
          code: 'BH',
        },
        {
          optionName: 'Holidays',
          code: 'MOL',
        },
        {
          optionName: 'Email settings',
          code: 'EMS',
        },
        {
          optionName: 'Google calendar integrations',
          code: 'GC',
        },
        {
          optionName: 'Paypal integrations',
          code: 'PP',
        },
        {
          optionName: 'Stripe integrations',
          code: 'STR',
        },
      ],
    },
  ];

  return (
    <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
      <Grid xs={12} sm={12} sx={{ marginTop: 1 }}>
        {parmissions.map((item) => (
          <>
            <Grid
              id="once-per-person"
              container
              alignItems="center"
              item
              xs={12}
              sx={{
                cursor: 'pointer',
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: '4px',
                marginTop: 1.0,
                height: 50,
                display: 'flex',
                justifyContent: 'space-between',
                paddingLeft: '.7rem',
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  color: '#3A3A50',
                }}
              >
                {item.parmissionName}
              </Typography>
              <Switch
                checked={permissions.includes(item.code)}
                onChange={(e) => {
                  e.target.checked
                    ? setPermissions([...permissions, item.code])
                    : setPermissions(
                        [...permissions].filter((e) => e != item.code)
                      );
                }}
              />
            </Grid>
            <Grid
              container
              alignItems="center"
              item
              xs={12}
              sx={{
                marginTop: 1.0,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {item.options?.map((data) => (
                <Grid
                  id="once-per-person"
                  container
                  alignItems="center"
                  item
                  xs={11}
                  sx={{
                    cursor: 'pointer',
                    border: '1px solid rgba(0, 0, 0, 0.23)',
                    borderRadius: '4px',
                    marginTop: 1.0,
                    height: 50,
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingLeft: '.7rem',
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: '#3A3A50',
                    }}
                  >
                    {data.optionName}
                  </Typography>
                  <Switch
                    checked={permissions.includes(data.code)}
                    onChange={(e) => {
                      e.target.checked
                        ? setPermissions([...permissions, data.code])
                        : setPermissions(
                            [...permissions].filter((e) => e != data.code)
                          );
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        ))}
      </Grid>
    </Grid>
  );
};

export default Permissions;
