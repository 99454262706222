import { Grid, TextField } from "@mui/material";
import React from "react";
import CustomTypography from "../CustomTypography";

function CustomTextField(props) {
  const customData = props.customData;

  return (
    <>
      <CustomTypography
        sx={{
          color: "#1976D2 ",
          fontSize: "16px",
          mb: "5px",
          ...customData?.labelSx,
        }}
      >
        {customData?.label} {props.required ? "*" : ""}
      </CustomTypography>
      <TextField
        inputProps={{
          style: {
            height: "18px",
            padding: 11,
          },
        }}
        {...props}
        sx={{
          width: "100%",
          "& fieldset": { borderRadius: 1.5 },
          ...props.sx,
        }}
      ></TextField>
    </>
  );
}

export default CustomTextField;
