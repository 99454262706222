import { configureStore } from "@reduxjs/toolkit";
import snackbarSlice from "./slices/snackbar.slice";
import userSlice from "./slices/user.slice.js";

export const store = configureStore({
  reducer: {
    snackbar: snackbarSlice,
    user: userSlice,
  },
});
