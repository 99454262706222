import { useState } from "react";
import React from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import InventoryIcon from "@mui/icons-material/Inventory";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import PaymentsIcon from "@mui/icons-material/Payments";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import SettingsIcon from "@mui/icons-material/Settings";
import UpnizeLogo from "../../assets/images/Upnize-logo.png";
import LogoutIcon from "@mui/icons-material/Logout";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import { Menu, MenuItem } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { selectUser, setUser } from "../../redux/slices/user.slice";
import { logout } from "../../Api/common/users";
// import { useCookies } from "react-cookie";

const drawerWidth = 240;

const System = ({ children }) => {
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down("md"));
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  // const [cookies, removeCookie] = useCookies(["access-token"]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userSlice = useSelector(selectUser);
  const user = JSON.parse(localStorage.getItem("user"));

  // checking for auth
  // if (!cookies["access-token"]) return <Navigate to="/login" replace={true} />;

  useEffect(() => {
    if (!user) {
      navigate("/login");
    } else {
      if (!userSlice?.id) {
        dispatch(setUser(user));
      }
    }
  }, [user]);

  // handlers
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClickMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const pages = [
    { text: "Dashboard", link: "/", icon: <InventoryIcon /> },
    { text: "Tenants", link: "/tenant", icon: <PersonIcon /> },
    { text: "Payments", link: "/payments", icon: <PaymentsIcon /> },
    { text: "Plans", link: "/plans", icon: <RocketLaunchIcon /> },
    { text: "Subscribers", link: "/subscribers", icon: <SubscriptionsIcon /> },
    { text: "News", link: "/news", icon: <NewspaperIcon /> },
    {
      text: "Notifications",
      link: "/notifications",
      icon: <NotificationsActiveIcon />,
    },
    { text: "Settings", link: "/settings", icon: <SettingsIcon /> },
  ];

  const drawer = (
    <div>
      <Toolbar style={{ height: "10vh" }}>
        <img src={UpnizeLogo} alt="logo" style={{ height: "45px" }} />
      </Toolbar>
      <Divider sx={{ bgcolor: "#3D4145" }} />
      <List>
        {pages.map((page, index) => (
          <Link
            to={page.link}
            style={{
              textDecoration: "none",
              "&:hover": { background: "#2E314A" },
            }}
          >
            <ListItem
              onClick={handleDrawerToggle}
              sx={
                location.pathname === page.link
                  ? {
                      backgroundColor: "#5D5D7E",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "#2E314A",
                      },
                    }
                  : { color: "#959595" }
              }
              button
              key={index}
            >
              <ListItemIcon
                sx={
                  location.pathname === page.link
                    ? { color: "#FFFFFF" }
                    : { color: "#8468FA" }
                }
              >
                {page.icon}
              </ListItemIcon>
              <ListItemText
                primary={page.text}
                sx={
                  location.pathname === page.link
                    ? { color: "white" }
                    : { color: "#626c76" }
                }
              />
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
  );

  return (
    <>
      {user && (
        <>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            onClose={handleCloseMenu}
            elevation={0}
            style={{ marginTop: "13px", left: -5 }}
            MenuListProps={{
              sx: {
                border: "1px solid #e3eaf3",
                borderRadius: "4px",
              },
            }}
          >
            <MenuItem>
              <ListItemIcon>
                <PersonIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Profile</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                logout().then((res) => {
                  if (res.status === 200) {
                    localStorage.removeItem("user");
                    navigate("/login");
                  }
                });
              }}
            >
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </MenuItem>
          </Menu>

          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            // aria-label="mailbox folders"
          >
            <AppBar
              position="fixed"
              sx={{
                width: { md: `calc(100% - ${drawerWidth}px)` },
                // height: "10vh",

                ml: { md: `${drawerWidth}px` },
                backgroundColor: "white",
                color: "black",
                boxShadow:
                  "0px 2px 4px -1px rgb(0 0 0 / 5%), 0px 4px 5px 0px rgb(0 0 0 / 5%), 0px 1px 10px 0px rgb(0 0 0 / 5%)",
              }}
            >
              <Toolbar
                sx={{
                  display: "flex",
                  justifyContent: isSmallScreen ? "space-between" : "end",
                }}
                style={{ padding: "10px 32px" }}
              >
                {isSmallScreen && (
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                  >
                    <MenuIcon />
                  </IconButton>
                )}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: { md: "space-between" },
                  }}
                >
                  <Grid
                    container
                    xs={12}
                    onClick={handleClickMenu}
                    sx={{
                      width: "100%",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      noWrap
                      component="div"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: 2,
                      }}
                    >
                      Hello {userSlice?.firstName}
                    </Typography>
                    <Avatar
                      alt="Remy Sharp"
                      src="https://demo.booknetic.com/wp-content/uploads/booknetic/avatar.jpg"
                    />
                  </Grid>
                </Box>
              </Toolbar>
            </AppBar>

            <Drawer
              // container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: "block", md: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                  backgroundColor: "#292D32",
                  color: "#6c757d",
                  fontSize: "24px",
                },
              }}
            >
              {drawer}
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: "none", md: "block" },
                "& ::-webkit-scrollbar": { width: "5px" },
                "& ::-webkit-scrollbar-track": {
                  // boxShadow: "inset 0 0 5px grey",
                  // borderRadius:" 10px",
                  backgroundColor: "#292D32",
                },
                "& ::-webkit-scrollbar-thumb": {
                  backgroundColor: "#6c757d",
                  borderRadius: "10px",
                },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                  backgroundColor: "#292D32",
                  color: "#6c757d",
                  fontWeight: "700",
                },
              }}
              open
            >
              {drawer}
            </Drawer>

            <Grid
              container
              sx={{
                minWidth: { xs: "100vw", md: `calc(100vw - ${drawerWidth}px)` },
                mt: "10vh",
                ml: { xs: "0px", md: `${drawerWidth}px` },
                backgroundColor: "#F5F7FA",
              }}
            >
              <div
                id="system"
                style={{
                  height: "90vh",
                  backgroundColor: "#F5F7FA",
                  width: "100%",
                  overflowX: "hidden",
                }}
              >
                {children}
              </div>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default System;
