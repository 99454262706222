import { Box, Button, Grid, Input, Menu, MenuItem, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CircleIcon from "@mui/icons-material/Circle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";

function createData(id, page, keyword, label, insertValue) {
    return { id, page, keyword, label, insertValue };
  }
  
  const rows = [
    createData(1, "Dashboard", "copied", "Copied", 4.0),
    createData(2, "Dashboard", "When longer text, we add it under centered", "When longer text, we add it under centered", 4.3),
    createData(3, "Dashboard", "copied", "Copied", 6.0),
    createData(4, "Dashboard", "copied", "Copied", 4.3),
    createData(5, "Dashboard", "N/A", "Copied", 3.9),
  ];


const Translate = ({ translateLanguage }) => {
  const [systemTranslate, setSystemTranslate] = useState("SuperAdmin");

  const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

  const handleClickMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
  <Menu
    anchorEl={anchorEl}
    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    transformOrigin={{ vertical: "top", horizontal: "center" }}
    open={open}
    onClose={handleCloseMenu}
    elevation={0}
    MenuListProps={{
      sx: { width: anchorEl && anchorEl.offsetWidth, border: "1px solid #e3eaf3", borderRadius: "4px"}
   }}
  >
    <MenuItem onClick={() => setSystemTranslate("SuperAdmin")}>
        SuperAdmin
      </MenuItem>
      <MenuItem onClick={() => setSystemTranslate("Admin")}>
        Admin
      </MenuItem>
      <MenuItem onClick={() => setSystemTranslate("Client")}>
        Client
      </MenuItem>
  </Menu>


      <Grid container xs={12} style={{ justifyContent: "flex-end" }}>
        <Button
          sx={{
            width: { xs: "100%", sm: "auto" },
            marginTop: { xs: 1, sm: 5 },
            marginBottom: 1,
            marginLeft: { xs: 0, sm: 1 },
            backgroundColor: "white",
            color: "#c1c1c1",
            height: "45px",
            borderRadius: "2px",
            fontSize: "12px",
            fontWeight: 600,
            boxShadow: "none",
            width: 150,
            border: "1px solid #c1c1c1",
            ":hover": {
              color: "white",
              backgroundColor: "#c1c1c1",
            },
          }}
          variant="contained"
          onClick={handleClickMenu}
          endIcon={<KeyboardArrowDownIcon sx={{ fontSize: "20px" }} />}
        >
          {systemTranslate}
        </Button>
        <Button
          sx={{
            width: { xs: "100%", sm: "auto" },
            marginTop: { xs: 1, sm: 5 },
            marginBottom: 1,
            marginLeft: { xs: 0, sm: 2 },
            background: '#4ABF60',
            "&:hover": {
              background: '#56DB6F'
            },
            height: "45px",
            borderRadius: "2px",
            fontSize: "12px",
            fontWeight: 600,
          }}
          variant="contained"
          color="success"
          onClick={() => {}}
        >
          <CheckIcon sx={{ marginRight: 1, fontSize: "20px" }} />
          Save Changes
        </Button>
      </Grid>

      <Grid xs={12} style={{ backgroundColor: "white", marginTop: "20px" }}>
        <Grid
          style={{ padding: "15px 25px", borderBottom: "1px solid #e3eaf3" }}
        >
          <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
            Language{" "}
            <span style={{ color: "#c1c1c1" }}>
              <CircleIcon sx={{ fontSize: "5px", marginX: "3px" }} /> Translate
              to {translateLanguage.name}{" "}
              <CircleIcon sx={{ fontSize: "5px", marginX: "3px" }} /> {systemTranslate}
            </span>
          </Typography>
        </Grid>
        <Grid container sx={{paddingBottom: "15px"}}>
          <TableContainer sx={{border: "none", boxShadow: "none"}} component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{fontWeight: 900}} width="5%">ID</TableCell>
                  <TableCell sx={{fontWeight: 900}} width="10%" align="left">Page</TableCell>
                  <TableCell sx={{fontWeight: 900}} width="10%" align="left">Keyword</TableCell>
                  <TableCell sx={{fontWeight: 900}} width="20%" align="left">Label</TableCell>
                  <TableCell sx={{fontWeight: 900}} width="45%" align="left">Insert your translate value here</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell width="5%" component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell width="12%" align="left">{row.page}</TableCell>
                    <TableCell width="12%" align="left">{row.keyword}</TableCell>
                    <TableCell width="20%" align="left">{row.label}</TableCell>
                    <TableCell width="45%" align="left"><textarea placeholder="Translate" style={{width: "100%", height :"50px", resize: "none", border: "1px solid #c1c1c1", outlineColor: "#c1c1c1", outlineWidth: "2px"}} /></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default Translate;
