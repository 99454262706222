import * as React from "react";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import Slide from "@mui/material/Slide";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Button, Grid, useMediaQuery } from "@mui/material";

export default function SaveSlideBar({
  handleCancel,
  handleSave,
  setIsSaveBar,
  isSaveBar,
  type,
}) {
  const containerRef = React.useRef(null);
  const mediaQuery1250 = useMediaQuery("(min-width:1250px)");

  return (
    <Box
      sx={{
        height: 80,
        width: { md: "calc(100% - 240px)", xs: "100%" },
        position: "fixed",
        right: 0,
        bottom: 0,
        zIndex: 9999,
        display: "flex",
        borderRadius: 1,
        justifyContent: "end",
      }}
      ref={containerRef}
    >
      <Grid
        md={type === "NOTIFICATIONS" ? 6 : mediaQuery1250 ? 9 : 8}
        sx={{
          width: "100%",
          bottom: 0,
          zIndex: 99,
          display: "flex",
          overflow: "hidden",
          justifyContent: "end",
        }}
      >
        <Slide direction="up" in={isSaveBar} container={containerRef.current}>
          <Box
            sx={{
              width:
                type === "NOTIFICATIONS"
                  ? { md: "calc(100% - 72px)", xs: "100%" }
                  : { md: "calc(100% - 80px)", xs: "100%" },
              mr:
                type === "NOTIFICATIONS"
                  ? { md: 5.5, xs: 0 }
                  : { md: "40px", xs: "0" },
              display: "flex",
              backgroundColor: "white",
              alignItems: "center",
              border: "#EBEBEB solid 1px",
            }}
          >
            <Button
              sx={{
                width: { xs: "100%", sm: "auto" },
                marginLeft: { xs: 3, sm: 4 },
                mr: { xs: 3, sm: 0 },
                height: "45px",
                fontSize: "12px",
                fontWeight: 600,
                backgroundColor: "WHITE",
                color: "GREY",
                ":hover": { backgroundColor: "GREY", color: "WHITE" },
              }}
              variant="contained"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              sx={{
                width: { xs: "100%", sm: "auto" },
                marginLeft: { xs: 0, sm: 2 },
                mr: { xs: 3, sm: 0 },
                backgroundColor: "#4ABF60 ",
                ":hover": { backgroundColor: "#56DB6F", color: "WHITE" },
                height: "45px",
                fontSize: "12px",
                fontWeight: 600,
              }}
              variant="contained"
              onClick={handleSave}
            >
              Save Changes
            </Button>
          </Box>
        </Slide>
      </Grid>
      {type === "NOTIFICATIONS" && <Grid md={3}></Grid>}
    </Box>
  );
}
