import React, { useLayoutEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import noPhotoImg from '../../assets/images/no-photo.png';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import DrawerFooter from '../../components/DrawerFooter';
import Drawer from '../../components/Drawer';
import { addNews as add, editNewsById } from '../../Api/Admin/news';
import QuillEditor from '../../components/QuillEditor';
import { useRef } from 'react';
import CustomButtonPages from '../../components/CustomButtonPages';

const AddNews = ({
  toggleAddNewsDrawer,
  drawerType,
  drawerData = null,
  fetchNews,
}) => {
  const [subject, setSubject] = useState('');
  const [type, setType] = useState('news');
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [quillHtml, setQuillHtml] = useState('');
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
  const buttonProps = {
    variant: 'contained',
    size: 'large',
  };

  const handleSubject = (e) => {
    setSubject(e.target.value);
    setErrors({ ...errors, subject: false });
  };

  const handleType = (selected) => {
    setType(selected);
  };

  // handling the image dragNdrop
  const uploadedFile = useRef();
  const handleUpload = () => {
    uploadedFile.current.click();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrop = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      [...ev.dataTransfer.items].forEach((item, i) => {
        // If dropped items aren't files, reject them
        if (item.kind === 'file') {
          const file = item.getAsFile();
          console.log(`… file[${i}].name = ${file.name}`);
          setImage(file);
          setImageFile(file);
        }
      });
    } else {
      // Use DataTransfer interface to access the file(s)
      [...ev.dataTransfer.files].forEach((file, i) => {
        console.log(`… file[${i}].name = ${file.name}`);
        setImage(file);
        setImageFile(file);
      });
    }
  };

  const readURL = (file) => {
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.onload = (e) => res(e.target.result);
      reader.onerror = (e) => rej(e);
      reader.readAsDataURL(file);
    });
  };

  const getImageURL = (image) => {
    readURL(image).then((url) => {
      setImage(url);
    });
  };

  // API

  // error handling
  const [errors, setErrors] = useState({});
  const requiredFields = ['subject', 'type'];

  const addNews = async () => {
    let data = {
      subject,
      type,
    };
    let err = {};
    for (const key in data) {
      if (!data[key] && requiredFields.includes(key)) {
        err = { ...err, [key]: true };
        console.log('s', data[key], data);
      }
      if (
        Array.isArray(data[key]) &&
        data[key].length === 0 &&
        requiredFields.includes(key)
      ) {
        console.log(data[key]);
        err = { ...err, [key]: true };
      }
    }
    setErrors({ ...err });
    if (Object.keys(err).length === 0) {
      let formData = new FormData();
      formData.append('title', subject);
      formData.append('type', type);
      formData.append('image', imageFile);
      formData.append('description', quillHtml);

      add(formData).then((res) => {
        toggleAddNewsDrawer();
        fetchNews();
      });
    }
  };

  const updateNews = async () => {
    let data = {
      subject,
      type,
    };
    let err = {};
    for (const key in data) {
      if (!data[key] && requiredFields.includes(key)) {
        err = { ...err, [key]: true };
        console.log('s', data[key], data);
      }
      if (
        Array.isArray(data[key]) &&
        data[key].length === 0 &&
        requiredFields.includes(key)
      ) {
        console.log(data[key]);
        err = { ...err, [key]: true };
      }
    }
    setErrors({ ...err });
    if (Object.keys(err).length === 0) {
      let formData = new FormData();
      formData.append('title', subject);
      formData.append('type', type);
      formData.append('image', imageFile);
      formData.append('description', quillHtml);

      editNewsById(formData, drawerData.id).then((res) => {
        toggleAddNewsDrawer();
        fetchNews();
      });
    }
  };

  useLayoutEffect(() => {
    if (drawerType != 'add') {
      setSubject(drawerData?.title);
      setImage(drawerData?.image);
      setType(drawerData?.type);
      setQuillHtml(drawerData?.description);
    }
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} sm={2}>
        <Drawer
          title={
            drawerType === 'add' ? 'Add News or blog' : 'Edit News or blog'
          }
          type={drawerType === 'add' ? 'ADD' : 'EDIT'}
          toggleDrawer={toggleAddNewsDrawer}
        >
          {/* -----------FORM----- */}
          <Grid
            item
            container
            sx={{
              paddingX: 3,
              paddingTop: 3,
              height: '100%',
              overflowY: 'auto',
            }}
          >
            <Grid xs={12}>
              <Grid item xs={12} sx={{ marginY: 1 }}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: '#3A3A50',
                  }}
                >
                  Subject<sapn style={{ color: 'red' }}>*</sapn>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  size="small"
                  required
                  variant="outlined"
                  sx={{
                    width: '100%',
                    fontSize: '14px',
                  }}
                  value={subject}
                  onChange={handleSubject}
                  helperText={errors.subject ? 'Value is missing' : ' '}
                  error={errors.subject}
                />
              </Grid>
            </Grid>

            <Grid xs={12}>
              <FormControl sx={{ m: 3 }} error={errors.type} variant="standard">
                <RadioGroup
                  row
                  name="radio-buttons-group"
                  value={type}
                  helperText={errors.type ? 'Value is missing' : ' '}
                  error={errors.type}
                >
                  <FormControlLabel
                    sx={{
                      color: '#3A3A50',
                    }}
                    value="news"
                    control={<Radio />}
                    label="Save as news"
                    onClick={() => {
                      handleType('news');
                    }}
                  />
                  <FormControlLabel
                    value="blog"
                    sx={{
                      color: '#3A3A50',
                    }}
                    control={<Radio />}
                    label="Save as blog"
                    onClick={() => {
                      handleType('blog');
                    }}
                  />
                  <FormControlLabel
                    value="help"
                    sx={{
                      color: '#3A3A50',
                    }}
                    control={<Radio />}
                    label="Save as Help center"
                    onClick={() => {
                      handleType('help');
                    }}
                  />
                </RadioGroup>
                <FormHelperText>
                  {errors.type ? 'Value is missing' : ' '}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid
              container
              alignItems="center"
              justifyContent="center"
              xs={12}
              sx={{
                border: '1px solid black',
                height: '230px',
                cursor: 'pointer',
              }}
              onClick={handleUpload}
              onDrop={(e) => handleDrop(e)}
              onDragOver={(e) => handleDragOver(e)}
              onDragEnter={(e) => handleDragEnter(e)}
              onDragLeave={(e) => handleDragLeave(e)}
            >
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <img
                  src={
                    image
                      ? typeof image === 'string'
                        ? image.includes('base64')
                          ? image
                          : (process.env.DEPLOY_TARGET === 'test'
                              ? 'https://t-backend.upnize.com/'
                              : 'https://backend.upnize.com/') + image
                        : getImageURL(image)
                      : noPhotoImg
                  }
                  style={{
                    borderRadius: image ? '0' : '50%',
                    width: image ? '100%' : 'auto',
                    height: image ? '230px' : 'auto',
                    objectFit: 'cover',
                  }}
                  alt=""
                />
                <TextField
                  id="outlined-basic"
                  required
                  sx={{
                    marginBottom: '1rem',
                    width: '100%',
                    fontSize: '14px',
                    display: 'none',
                  }}
                  onChange={(e) => {
                    setImage(e.target.files[0]);
                    setImageFile(e.target.files[0]);
                  }}
                  inputRef={uploadedFile}
                  type="file"
                />
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: 600, marginTop: 1 }}
                >
                  Upload Image
                </Typography>
              </Grid>
            </Grid>

            <Grid xs={12}>
              <Grid item xs={12} sx={{ mb: 1 }}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: '#3A3A50',
                  }}
                >
                  Description
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <QuillEditor
                  onChange={({ html }) => {
                    setQuillHtml(html);
                  }}
                  value={quillHtml}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* -----Button */}
          <DrawerFooter>
            {drawerType === 'add' && (
              <>
                <div
                  style={{
                    display: 'flex',
                    gap: '15px',
                    justifyContent: isSmallScreen
                      ? 'space-between'
                      : 'flex-end',
                    width: '100%',
                  }}
                >
                  <CustomButtonPages
                    title="Cancel"
                    isIcon={false}
                    onClick={toggleAddNewsDrawer}
                    sx={{
                      width: isSmallScreen ? '48%' : '7rem',
                      marginLeft: { xs: 1, sm: 2 },
                    }}
                  />
                  <CustomButtonPages
                    title="ADD"
                    isIcon={false}
                    blue={true}
                    onClick={addNews}
                    sx={{
                      width: isSmallScreen ? '48%' : '7rem',
                      marginLeft: { xs: 1, sm: 2 },
                    }}
                  />
                </div>
              </>
            )}
            {drawerType === 'edit' && (
              <>
                <div
                  style={{
                    display: 'flex',
                    gap: '15px',
                    justifyContent: isSmallScreen
                      ? 'space-between'
                      : 'flex-end',
                    width: '100%',
                  }}
                >
                  <CustomButtonPages
                    title="Cancel"
                    isIcon={false}
                    onClick={toggleAddNewsDrawer}
                    sx={{
                      width: isSmallScreen ? '48%' : '7rem',
                      marginLeft: { xs: 1, sm: 2 },
                    }}
                  />
                  <CustomButtonPages
                    title="SAVE"
                    isIcon={false}
                    blue={true}
                    onClick={updateNews}
                    sx={{
                      width: isSmallScreen ? '48%' : '7rem',
                      marginLeft: { xs: 1, sm: 2 },
                    }}
                  />
                </div>
              </>
            )}
          </DrawerFooter>
        </Drawer>
      </Grid>
    </Grid>
  );
};

export default AddNews;
